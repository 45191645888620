import type { ReactNode } from 'react'
import React, { createContext, useContext, useMemo } from 'react'

import type { BreakpointsResult } from '../hooks/useBreakpoints'
import useThemeBreakpoints from '../hooks/useBreakpoints'

export type BreakpointsContextType = BreakpointsResult | undefined

const BreakpointsContext = createContext<BreakpointsContextType>(undefined)

const BreakpointsProvider = ({ children }: { children?: ReactNode }) => {
  const breakpoints = useThemeBreakpoints()
  const value = useMemo(() => breakpoints, [JSON.stringify(breakpoints)])

  return <BreakpointsContext.Provider value={value}>{children}</BreakpointsContext.Provider>
}

const useBreakpoints = () => {
  const context = useContext(BreakpointsContext)
  if (context === undefined) {
    throw new Error('useBreakpoints must be used within a BreakpointsProvider')
  }
  return context
}

export { BreakpointsProvider, useBreakpoints }
