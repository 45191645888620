import type { ElementRef, FunctionComponent, ReactNode } from 'react'
import React, { forwardRef } from 'react'

import Tooltip from '../../components/core/Tooltip/Tooltip'

export interface WithTooltipProps {
  'data-e2e'?: string
  tooltip?: ReactNode
  trigger?: 'hover' | 'click'
}

const withTooltip = <T extends object>(Component: FunctionComponent<T>) =>
  forwardRef<ElementRef<typeof Component>, T & WithTooltipProps>((props, ref) => {
    const { 'data-e2e': dataE2e, tooltip, trigger = 'hover', ...rest } = props

    if (tooltip) {
      return (
        <Tooltip
          data-e2e={`${dataE2e}--tooltip`}
          fallbackPlacements={['auto']}
          placement="top"
          tooltip={tooltip}
          trigger={trigger}
          variant="black"
        >
          <Component {...(rest as T)} data-e2e={dataE2e} ref={ref} />
        </Tooltip>
      )
    }

    return <Component {...(rest as T)} data-e2e={dataE2e} ref={ref} />
  })

export default withTooltip
