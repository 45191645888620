import type { ReactNode } from 'react'
import React, { memo } from 'react'

import Inline from '../../layout/Inline/Inline'
import Stack from '../../layout/Stack/Stack'
import Message from '../Input/Message/Message'
import Label from '../Label/Label'
import type { FormComponent } from '../interfaces'
import { RadioGroupContainer } from './RadioGroup.styles'
import type { RadioFormatType, ValueType } from './context/RadioGroupContext'
import { RadioGroupProvider } from './context/RadioGroupContext'

export interface RadioGroupProps extends FormComponent<ValueType> {
  allowDeselection?: boolean
  children?: ReactNode
  collapsed?: boolean
  fluid?: boolean
  format?: RadioFormatType
  inlined?: boolean
  name: string
  noWrap?: boolean
  reversed?: boolean
}

const RadioGroup = ({
  'data-e2e': dataE2e,
  allowDeselection = false,
  children,
  collapsed = false,
  disabled = false,
  errorMessage,
  fluid = false,
  format = 'default',
  helpText,
  inlined = false,
  label,
  name,
  noWrap,
  onChange,
  required = false,
  reversed = false,
  value,
  warningMessage,
}: RadioGroupProps) => (
  <RadioGroupProvider
    allowDeselection={allowDeselection}
    disabled={disabled}
    format={format}
    hasError={!!errorMessage}
    hasWarning={!!warningMessage}
    name={name}
    onChange={onChange}
    value={value}
  >
    <RadioGroupContainer
      collapsed={collapsed}
      data-e2e={dataE2e}
      fluid={fluid}
      format={format}
      noWrap={noWrap}
      reversed={reversed}
    >
      {!!label && <Label text={label} helpText={helpText} required={required} />}

      {inlined || format === 'toggled' ? (
        <Inline
          alignment="center"
          fluid={fluid}
          inline
          noWrap={noWrap}
          spacing={collapsed ? undefined : 'small'}
        >
          {children}
        </Inline>
      ) : (
        <Stack spacing="xsmall" inline fullWidth={fluid}>
          {children}
        </Stack>
      )}

      {!!errorMessage && (
        <Message data-e2e="radio-group-error-message" type="error" value={errorMessage} />
      )}
      {!!warningMessage && !errorMessage && (
        <Message data-e2e="radio-group-warning-message" type="warning" value={warningMessage} />
      )}
    </RadioGroupContainer>
  </RadioGroupProvider>
)

export default memo(RadioGroup)
