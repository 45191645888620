import type { ReactNode } from 'react'
import React from 'react'

import type { Alignmentype, Size } from '../../../themes/types'
import { StyledInline } from './Inline.styles'

export interface InlineProps {
  'data-e2e'?: string
  alignment?: Alignmentype
  children?: ReactNode
  className?: string
  fluid?: boolean
  inline?: boolean
  noWrap?: boolean
  spacing?: Size
}

const Inline = ({
  'data-e2e': dataE2e,
  alignment = 'baseline',
  children,
  className,
  fluid = false,
  inline = false,
  noWrap = false,
  spacing,
}: InlineProps) => (
  <StyledInline
    alignment={alignment}
    className={className}
    data-e2e={dataE2e}
    fluid={fluid}
    inline={inline}
    noWrap={noWrap}
    spacing={spacing}
  >
    {children}
  </StyledInline>
)

export default Inline
