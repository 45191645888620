import type { ReactNode } from 'react'
import React from 'react'

import type { Variant, Width } from '../../../themes/types'
import type { FontSize } from '../../../themes/types/typography.type'
import { Inline } from '../../layout'
import Infotip from '../Infotip/Infotip'
import { StyledText } from './Text.styles'

type As = 'div' | 'span' | 'p' | 'h1' | 'h2' | 'h3' | 'ol' | 'ul'
type FontCase = 'capitalize' | 'lowercase' | 'uppercase'
export interface TextProps {
  'data-e2e'?: string
  as?: As
  bold?: boolean
  children?: ReactNode
  className?: string
  ellipsis?: boolean
  fontCase?: FontCase
  heading?: boolean
  helpText?: ReactNode
  italic?: boolean
  noWrap?: boolean
  quiet?: boolean
  size?: FontSize
  underline?: boolean
  variant?: Variant
  width?: Width
  wrap?: boolean
}

const TextComponent = ({
  'data-e2e': datae2e,
  as = 'span',
  bold = false,
  children,
  className,
  ellipsis,
  fontCase,
  heading = false,
  helpText,
  italic = false,
  noWrap = false,
  quiet = false,
  size = 'medium',
  underline = false,
  variant,
  width,
  wrap = false,
}: TextProps) =>
  helpText ? (
    <Inline spacing="xsmall" alignment="center" noWrap={!wrap}>
      <StyledText
        $noWrap={noWrap}
        $wrap={wrap}
        as={as}
        bold={bold}
        className={className}
        data-e2e={datae2e}
        ellipsis={ellipsis}
        fontCase={fontCase}
        heading={heading}
        italic={italic}
        quiet={quiet}
        size={size}
        underline={underline}
        variant={variant}
        width={width}
      >
        {children}
      </StyledText>
      <Infotip data-e2e={`${datae2e}--infotip`} helpText={helpText} />
    </Inline>
  ) : (
    <StyledText
      $noWrap={noWrap}
      $wrap={wrap}
      as={as}
      bold={bold}
      className={className}
      data-e2e={datae2e}
      ellipsis={ellipsis}
      fontCase={fontCase}
      heading={heading}
      italic={italic}
      quiet={quiet}
      size={size}
      underline={underline}
      variant={variant}
      width={width}
    >
      {children}
    </StyledText>
  )

export default TextComponent
