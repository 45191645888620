/* eslint-disable guard-for-in */
import { library } from '@fortawesome/fontawesome-svg-core'
import * as fad from '@fortawesome/pro-duotone-svg-icons'
import * as fal from '@fortawesome/pro-light-svg-icons'
import * as far from '@fortawesome/pro-regular-svg-icons'
import * as fas from '@fortawesome/pro-solid-svg-icons'

enum AcceptedTransform {
  DOWN = 'down',
  FLIP_H = 'flipHorizontal',
  FLIP_V = 'flipVertical',
  GROW = 'grow',
  LEFT = 'left',
  RIGHT = 'right',
  ROTATE = 'rotate',
  SHRINK = 'shrink',
  UP = 'up',
}

export interface Transform {
  down?: number
  flipHorizontal?: boolean
  flipVertical?: boolean
  grow?: number
  left?: number
  right?: number
  rotate?: number
  shrink?: number
  up?: number
}

export const iconSizes = [
  'lg',
  'xs',
  'sm',
  '1x',
  '2x',
  '3x',
  '4x',
  '5x',
  '6x',
  '7x',
  '8x',
  '9x',
  '10x',
] as const
export type IconSize = (typeof iconSizes)[number]

export const usedIcons = [
  'address-book',
  'apple-alt',
  'arrow-left',
  'arrow-right-from-bracket',
  'arrow-right',
  'badge-percent',
  'ban',
  'bars',
  'basket-shopping',
  'bell',
  'book',
  'book-open',
  'box-alt',
  'box-taped',
  'boxes-stacked',
  'buildings',
  'calculator',
  'calendar-alt',
  'calendar-clock',
  'calendar-range',
  'calendar',
  'caret-circle-down',
  'caret-down',
  'caret-left',
  'caret-up',
  'cart-circle-plus',
  'cart-plus',
  'cart-shopping',
  'chart-column',
  'chart-mixed-up-circle-dollar',
  'chart-pie',
  'check-circle',
  'check',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'circle-user',
  'circle',
  'circle-xmark',
  'clock',
  'coffee',
  'comment-lines',
  'comment-plus',
  'comment',
  'comments',
  'compress',
  'copy',
  'cup-straw-swoosh',
  'dolly',
  'download',
  'edit',
  'ellipsis-v',
  'envelope',
  'exclamation-circle',
  'exclamation-triangle',
  'expand',
  'eye-slash',
  'eye',
  'file-alt',
  'file-csv',
  'file-exclamation',
  'file-import',
  'file-pdf',
  'file-xls',
  'fish',
  'gift',
  'heart',
  'history',
  'hourglass-clock',
  'hourglass-end',
  'hourglass-half',
  'image',
  'info-circle',
  'knife',
  'language',
  'laptop-house',
  'lemon',
  'level-down',
  'link-slash',
  'link',
  'list-check',
  'lock',
  'meat',
  'megaphone',
  'meh',
  'message-dollar',
  'minus',
  'mobile-android-alt',
  'paper-plane',
  'paperclip',
  'pen-to-square',
  'pepper-hot',
  'phone-alt',
  'phone-office',
  'phone-square-alt',
  'plus',
  'print-slash',
  'print',
  'question-circle',
  'receipt',
  'redo',
  'rotate',
  'save',
  'scale-balanced',
  'search',
  'share-square',
  'shop',
  'shopping-basket',
  'shopping-cart',
  'slash',
  'sliders',
  'sparkles',
  'star',
  'table',
  'tag',
  'telescope',
  'thumbtack',
  'times-circle',
  'times',
  'tomato',
  'trash-alt',
  'truck',
  'truck-arrow-right',
  'truck-fast',
  'truck-clock',
  'truck-fast',
  'truck',
  'up-right-from-square',
  'upload',
  'user-pen',
  'user-tag',
  'user-xmark',
  'user-check',
  'user',
  'users',
  'xmark',
] as const

type ElementType<T extends ReadonlyArray<unknown>> =
  T extends ReadonlyArray<infer ElementType> ? ElementType : never

export type UsedIcons = ElementType<typeof usedIcons>

export const formatTransform = (transform?: Transform) => {
  if (!transform) return undefined

  return Object.keys(transform)
    .map(key => {
      switch (key) {
        case AcceptedTransform.FLIP_H:
          return `flip-h`
        case AcceptedTransform.FLIP_V:
          return `flip-v`

        default:
          return `${key}-${transform[key as keyof Transform]}`
      }
    })
    .join(' ')
}

export const addIcons = () =>
  library.add(
    // fad
    fad.faAddressBook,
    fad.faAppleAlt,
    fad.faArrowLeft,
    fad.faArrowRight,
    fad.faArrowRightFromBracket,
    fad.faBadgePercent,
    fad.faBan,
    fad.faBars,
    fad.faBasketShopping,
    fad.faBell,
    fad.faBook,
    fad.faBookOpen,
    fad.faBoxAlt,
    fad.faBoxTaped,
    fad.faBoxesStacked,
    fad.faBuildings,
    fad.faCalculator,
    fad.faCalendar,
    fad.faCalendarAlt,
    fad.faCalendarClock,
    fad.faCalendarRange,
    fad.faCaretCircleDown,
    fad.faCaretDown,
    fad.faCaretLeft,
    fad.faCaretUp,
    fad.faCartCirclePlus,
    fad.faCartPlus,
    fad.faCartShopping,
    fad.faChartColumn,
    fad.faChartMixedUpCircleDollar,
    fad.faChartPie,
    fad.faCheck,
    fad.faCheckCircle,
    fad.faChevronDown,
    fad.faChevronLeft,
    fad.faChevronRight,
    fad.faChevronUp,
    fad.faCircle,
    fad.faCircleUser,
    fad.faCircleXmark,
    fad.faClock,
    fad.faCoffee,
    fad.faComment,
    fad.faCommentLines,
    fad.faCommentPlus,
    fad.faComments,
    fad.faCompress,
    fad.faCopy,
    fad.faCupStrawSwoosh,
    fad.faDolly,
    fad.faDownload,
    fad.faEdit,
    fad.faEllipsisV,
    fad.faEnvelope,
    fad.faExclamationCircle,
    fad.faExclamationTriangle,
    fad.faExpand,
    fad.faEye,
    fad.faEyeSlash,
    fad.faFileAlt,
    fad.faFileCsv,
    fad.faFileExclamation,
    fad.faFileImport,
    fad.faFilePdf,
    fad.faFileXls,
    fad.faFish,
    fad.faGift,
    fad.faHeart,
    fad.faHistory,
    fad.faHourglassClock,
    fad.faHourglassEnd,
    fad.faHourglassHalf,
    fad.faImage,
    fad.faInfoCircle,
    fad.faKnife,
    fad.faLanguage,
    fad.faLaptopHouse,
    fad.faLemon,
    fad.faLevelDown,
    fad.faLink,
    fad.faLinkSlash,
    fad.faListCheck,
    fad.faLock,
    fad.faMeat,
    fad.faMegaphone,
    fad.faMeh,
    fad.faMessageDollar,
    fad.faMinus,
    fad.faMobileAndroidAlt,
    fad.faPaperclip,
    fad.faPaperPlane,
    fad.faPenToSquare,
    fad.faPepperHot,
    fad.faPhoneAlt,
    fad.faPhoneOffice,
    fad.faPhoneSquareAlt,
    fad.faPlus,
    fad.faPrint,
    fad.faPrintSlash,
    fad.faQuestionCircle,
    fad.faReceipt,
    fad.faRedo,
    fad.faRotate,
    fad.faSave,
    fad.faScaleBalanced,
    fad.faSearch,
    fad.faShareSquare,
    fad.faShoppingCart,
    fad.faShoppingBasket,
    fad.faSlash,
    fad.faSliders,
    fad.faShop,
    fad.faSparkles,
    fad.faStar,
    fad.faTable,
    fad.faTag,
    fad.faTelescope,
    fad.faThumbtack,
    fad.faTimes,
    fad.faTimesCircle,
    fad.faTomato,
    fad.faTrashAlt,
    fad.faTruck,
    fad.faTruckArrowRight,
    fad.faTruckClock,
    fad.faTruckFast,
    fad.faUpRightFromSquare,
    fad.faUpload,
    fad.faUser,
    fad.faUserPen,
    fad.faUserXmark,
    fad.faUserCheck,
    fad.faUsers,
    fad.faUserTag,
    fad.faXmark,
    // fal
    fal.faAddressBook,
    fal.faAppleAlt,
    fal.faArrowLeft,
    fal.faArrowRight,
    fal.faArrowRightFromBracket,
    fal.faBadgePercent,
    fal.faBan,
    fal.faBars,
    fal.faBasketShopping,
    fal.faBell,
    fal.faBook,
    fal.faBookOpen,
    fal.faBoxAlt,
    fal.faBoxTaped,
    fal.faBoxesStacked,
    fal.faBuildings,
    fal.faCalculator,
    fal.faCalendar,
    fal.faCalendarAlt,
    fal.faCalendarClock,
    fal.faCalendarRange,
    fal.faCaretCircleDown,
    fal.faCaretDown,
    fal.faCaretLeft,
    fal.faCaretUp,
    fal.faCartCirclePlus,
    fal.faCartPlus,
    fal.faCartShopping,
    fal.faChartColumn,
    fal.faChartMixedUpCircleDollar,
    fal.faChartPie,
    fal.faCheck,
    fal.faCheckCircle,
    fal.faChevronDown,
    fal.faChevronLeft,
    fal.faChevronRight,
    fal.faChevronUp,
    fal.faCircle,
    fal.faCircleUser,
    fal.faCircleXmark,
    fal.faClock,
    fal.faCoffee,
    fal.faComment,
    fal.faCommentLines,
    fal.faCommentPlus,
    fal.faComments,
    fal.faCompress,
    fal.faCopy,
    fal.faCupStrawSwoosh,
    fal.faDolly,
    fal.faDownload,
    fal.faEdit,
    fal.faEllipsisV,
    fal.faEnvelope,
    fal.faExclamationCircle,
    fal.faExclamationTriangle,
    fal.faExpand,
    fal.faEye,
    fal.faEyeSlash,
    fal.faFileAlt,
    fal.faFileCsv,
    fal.faFileExclamation,
    fal.faFileImport,
    fal.faFilePdf,
    fal.faFileXls,
    fal.faFish,
    fal.faGift,
    fal.faHeart,
    fal.faHistory,
    fal.faHourglassClock,
    fal.faHourglassEnd,
    fal.faHourglassHalf,
    fal.faImage,
    fal.faInfoCircle,
    fal.faKnife,
    fal.faLanguage,
    fal.faLaptopHouse,
    fal.faLemon,
    fal.faLevelDown,
    fal.faLink,
    fal.faLinkSlash,
    fal.faListCheck,
    fal.faLock,
    fal.faMeat,
    fal.faMegaphone,
    fal.faMeh,
    fal.faMessageDollar,
    fal.faMinus,
    fal.faMobileAndroidAlt,
    fal.faPaperclip,
    fal.faPaperPlane,
    fal.faPenToSquare,
    fal.faPepperHot,
    fal.faPhoneAlt,
    fal.faPhoneOffice,
    fal.faPhoneSquareAlt,
    fal.faPlus,
    fal.faPrint,
    fal.faPrintSlash,
    fal.faQuestionCircle,
    fal.faReceipt,
    fal.faRedo,
    fal.faRotate,
    fal.faSave,
    fal.faScaleBalanced,
    fal.faShareSquare,
    fal.faSearch,
    fal.faShoppingCart,
    fal.faShoppingBasket,
    fal.faSlash,
    fal.faSliders,
    fal.faShop,
    fal.faSparkles,
    fal.faStar,
    fal.faTable,
    fal.faTag,
    fal.faTelescope,
    fal.faThumbTack,
    fal.faTimes,
    fal.faTimesCircle,
    fal.faTomato,
    fal.faTrashAlt,
    fal.faTruck,
    fal.faTruckArrowRight,
    fal.faTruckClock,
    fal.faTruckFast,
    fal.faUpRightFromSquare,
    fal.faUpload,
    fal.faUser,
    fal.faUsers,
    fal.faUserPen,
    fal.faUserXmark,
    fal.faUserCheck,
    fal.faUserTag,
    fal.faXmark,
    // far
    far.faAddressBook,
    far.faAppleAlt,
    far.faArrowLeft,
    far.faArrowRight,
    far.faArrowRightFromBracket,
    far.faBadgePercent,
    far.faBan,
    far.faBars,
    far.faBasketShopping,
    far.faBell,
    far.faBook,
    far.faBookOpen,
    far.faBoxAlt,
    far.faBoxTaped,
    far.faBoxesStacked,
    far.faBuildings,
    far.faCalculator,
    far.faCalendar,
    far.faCalendarAlt,
    far.faCalendarClock,
    far.faCalendarRange,
    far.faCaretCircleDown,
    far.faCaretDown,
    far.faCaretLeft,
    far.faCaretUp,
    far.faCartCirclePlus,
    far.faCartPlus,
    far.faCartShopping,
    far.faChartMixedUpCircleDollar,
    far.faChartColumn,
    far.faChartPie,
    far.faCheck,
    far.faCheckCircle,
    far.faChevronDown,
    far.faChevronLeft,
    far.faChevronRight,
    far.faChevronUp,
    far.faCircle,
    far.faCircleUser,
    far.faCircleXmark,
    far.faClock,
    far.faCoffee,
    far.faComment,
    far.faCommentLines,
    far.faCommentPlus,
    far.faComments,
    far.faCompress,
    far.faCopy,
    far.faCupStrawSwoosh,
    far.faDolly,
    far.faDownload,
    far.faEdit,
    far.faEllipsisV,
    far.faEnvelope,
    far.faExclamationCircle,
    far.faExclamationTriangle,
    far.faExpand,
    far.faEye,
    far.faEyeSlash,
    far.faFileAlt,
    far.faFileCsv,
    far.faFileExclamation,
    far.faFileImport,
    far.faFilePdf,
    far.faFileXls,
    far.faFish,
    far.faGift,
    far.faHeart,
    far.faHistory,
    far.faHourglassClock,
    far.faHourglassEnd,
    far.faHourglassHalf,
    far.faImage,
    far.faInfoCircle,
    far.faKnife,
    far.faLanguage,
    far.faLaptopHouse,
    far.faLemon,
    far.faLevelDown,
    far.faLink,
    far.faLinkSlash,
    far.faListCheck,
    far.faLock,
    far.faMeat,
    far.faMegaphone,
    far.faMeh,
    far.faMessageDollar,
    far.faMinus,
    far.faMobileAndroidAlt,
    far.faPaperclip,
    far.faPaperPlane,
    far.faPenToSquare,
    far.faPepperHot,
    far.faPhoneAlt,
    far.faPhoneOffice,
    far.faPhoneSquareAlt,
    far.faPlus,
    far.faPrint,
    far.faPrintSlash,
    far.faQuestionCircle,
    far.faReceipt,
    far.faRedo,
    far.faRotate,
    far.faShareSquare,
    far.faSave,
    far.faScaleBalanced,
    far.faSearch,
    far.faShoppingCart,
    far.faShoppingBasket,
    far.faSlash,
    far.faSliders,
    far.faShop,
    far.faSparkles,
    far.faStar,
    far.faTable,
    far.faTag,
    far.faTelescope,
    far.faThumbTack,
    far.faTimes,
    far.faTimesCircle,
    far.faTomato,
    far.faTrashAlt,
    far.faTruck,
    far.faTruckArrowRight,
    far.faTruckClock,
    far.faTruckFast,
    far.faUpRightFromSquare,
    far.faUpload,
    far.faUser,
    far.faUserPen,
    far.faUserXmark,
    far.faUserCheck,
    far.faUsers,
    far.faUserTag,
    far.faXmark,
    // fas
    fas.faAddressBook,
    fas.faAppleAlt,
    fas.faArrowLeft,
    fas.faArrowRight,
    fas.faArrowRightFromBracket,
    fas.faBadgePercent,
    fas.faBan,
    fas.faBars,
    fas.faBasketShopping,
    fas.faBell,
    fas.faBook,
    fas.faBookOpen,
    fas.faBoxAlt,
    fas.faBoxTaped,
    fas.faBoxesStacked,
    fas.faBuildings,
    fas.faCalculator,
    fas.faCalendar,
    fas.faCalendarAlt,
    fas.faCalendarClock,
    fas.faCalendarRange,
    fas.faCaretCircleDown,
    fas.faCaretDown,
    fas.faCaretLeft,
    fas.faCaretUp,
    fas.faCartCirclePlus,
    fas.faCartPlus,
    fas.faCartShopping,
    fas.faChartColumn,
    fas.faChartMixedUpCircleDollar,
    fas.faChartPie,
    fas.faCheck,
    fas.faCheckCircle,
    fas.faChevronDown,
    fas.faChevronLeft,
    fas.faChevronRight,
    fas.faChevronUp,
    fas.faCircle,
    fas.faCircleUser,
    fas.faCircleXmark,
    fas.faClock,
    fas.faCoffee,
    fas.faComment,
    fas.faCommentLines,
    fas.faCommentPlus,
    fas.faComments,
    fas.faCompress,
    fas.faCopy,
    fas.faCupStrawSwoosh,
    fas.faDolly,
    fas.faDownload,
    fas.faEdit,
    fas.faEllipsisV,
    fas.faEnvelope,
    fas.faExclamationCircle,
    fas.faExclamationTriangle,
    fas.faExpand,
    fas.faEye,
    fas.faEyeSlash,
    fas.faFileAlt,
    fas.faFileCsv,
    fas.faFileExclamation,
    fas.faFish,
    fas.faFileImport,
    fas.faFilePdf,
    fas.faFileXls,
    fas.faGift,
    fas.faHeart,
    fas.faHistory,
    fas.faHourglassClock,
    fas.faHourglassEnd,
    fas.faHourglassHalf,
    fas.faImage,
    fas.faInfoCircle,
    fas.faKnife,
    fas.faLanguage,
    fas.faLaptopHouse,
    fas.faLemon,
    fas.faLevelDown,
    fas.faLink,
    fas.faLinkSlash,
    fas.faListCheck,
    fas.faLock,
    fas.faMeat,
    fas.faMegaphone,
    fas.faMeh,
    fas.faMessageDollar,
    fas.faMinus,
    fas.faMobileAndroidAlt,
    fas.faPaperclip,
    fas.faPaperPlane,
    fas.faPenToSquare,
    fas.faPepperHot,
    fas.faPhoneAlt,
    fas.faPhoneOffice,
    fas.faPhoneSquareAlt,
    fas.faPlus,
    fas.faPrint,
    fas.faPrintSlash,
    fas.faQuestionCircle,
    fas.faReceipt,
    fas.faRedo,
    fas.faRotate,
    fas.faShareSquare,
    fas.faSave,
    fas.faScaleBalanced,
    fas.faSearch,
    fas.faShoppingCart,
    fas.faShoppingBasket,
    fas.faSlash,
    fas.faSliders,
    fas.faShop,
    fas.faSparkles,
    fas.faStar,
    fas.faTable,
    fas.faTag,
    fas.faTelescope,
    fas.faThumbTack,
    fas.faTimes,
    fas.faTimesCircle,
    fas.faTomato,
    fas.faTrashAlt,
    fas.faTruck,
    fas.faTruckArrowRight,
    fas.faTruckClock,
    fas.faTruckFast,
    fas.faUpRightFromSquare,
    fas.faUpload,
    fas.faUser,
    fas.faUserPen,
    fas.faUserXmark,
    fas.faUserCheck,
    fas.faUsers,
    fas.faUserTag,
    fas.faXmark
  )
