import type { HTMLAttributes } from 'react'
import React from 'react'

import type { Variant, Width } from '../../../themes'
import { Circle, SvgLoader } from './Loader.styles'

export interface LoaderProps extends HTMLAttributes<SVGElement> {
  'data-e2e'?: string
  centered?: boolean
  classname?: string
  size?: Width
  speed?: number
  still?: boolean
  thickness?: number
  variant?: Variant
}

const Loader = ({
  'data-e2e': dataE2e,
  centered = false,
  className,
  size = '50px',
  speed = 100,
  still = false,
  thickness = 100,
  variant = 'neutral',
  ...svgProps
}: LoaderProps) => {
  const strokeWidth = 4 * (thickness / 100)

  return (
    <SvgLoader
      centered={centered}
      className={className}
      data-e2e={dataE2e}
      fill="none"
      variant={variant}
      viewBox="0 0 66 66"
      width={size}
      {...svgProps}
    >
      <Circle cx="33" cy="33" fill="none" r="28" strokeWidth={strokeWidth} variant={variant} />

      <Circle
        cx="33"
        cy="33"
        fill="none"
        r="28"
        speed={speed}
        still={still}
        stroke="currentColor"
        strokeDasharray="1, 174"
        strokeDashoffset="306"
        strokeLinecap="round"
        strokeWidth={strokeWidth}
      />
    </SvgLoader>
  )
}

export default Loader
