import React, { memo, useCallback, useState } from 'react'
import type ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { useEffectAfterMount } from '../../../hooks'
import type { IconName } from '../../core'
import { Icon } from '../../core'
import Message from '../Input/Message/Message'
import Label from '../Label/Label'
import { getInputVariant } from '../form.utils'
import type { InputProps } from '../interfaces'
import {
  CalendarContainer,
  DatepickerAndIconWrapper,
  DatepickerContainer,
  DatepickerInput,
  IconContainer,
} from './Datepicker.styles'

export interface HighlightRange {
  'react-datepicker__day--highlighted-danger'?: Date[]
  'react-datepicker__day--highlighted-neutral'?: Date[]
  'react-datepicker__day--highlighted-primary'?: Date[]
  'react-datepicker__day--highlighted-secondary'?: Date[]
  'react-datepicker__day--highlighted-success'?: Date[]
  'react-datepicker__day--highlighted-warning'?: Date[]
}

export type ValueType<WithRange> = WithRange extends false
  ? Date | null
  : [Date | null, Date | null]

export interface DatepickerProps<WithRange = false> extends InputProps<ValueType<WithRange>> {
  clearable?: boolean
  excludeDates?: Date[]
  filterDate?: (date: Date) => boolean
  highlightDates?: Date[] | HighlightRange[]
  icon?: IconName
  includeDates?: Date[]
  maxDate?: Date
  minDate?: Date
  onCalendarClose?: () => void
  onInputClick?: () => void
  open?: boolean
  portalId?: string
  selectsEnd?: boolean
  withRange?: WithRange
  withPortal?: boolean
}

const Datepicker = <WithRange extends boolean = false>({
  'data-e2e': dataE2e,
  autofocus,
  className,
  clearable = true,
  disabled = false,
  errorMessage,
  excludeDates,
  filterDate,
  fluid,
  hasError,
  hasWarning,
  highlightDates,
  helpText,
  icon = 'calendar-alt',
  includeDates,
  label,
  maxDate,
  minDate,
  name,
  onBlur,
  onCalendarClose,
  onChange,
  onInputClick,
  open,
  placeholder,
  portalId,
  required = false,
  selectsEnd,
  withRange = false as WithRange,
  value,
  warningMessage,
  withPortal = false,
}: DatepickerProps<WithRange>) => {
  const [innerValue, setInnerValue] = useState(value)
  const isArrayValue = Array.isArray(innerValue)
  const [startDate, endDate] = isArrayValue ? innerValue : []
  const filled = isArrayValue ? !!startDate : !!innerValue
  let inputEl: ReactDatePicker<boolean | undefined, boolean | undefined> | null

  const variant = getInputVariant({ errorMessage, hasError, hasWarning, warningMessage })

  useEffectAfterMount(() => setInnerValue(value), [value])

  const handleChange = useCallback(
    (date: ValueType<WithRange>) => {
      setInnerValue(date)
      onChange?.({ name, value: date })
    },
    [name, onChange]
  )

  return (
    <DatepickerContainer
      className={className}
      disabled={disabled}
      filled={filled}
      fluid={fluid}
      withPortal={withPortal}
    >
      {!!label && <Label text={label} htmlFor={name} helpText={helpText} required={required} />}

      <DatepickerAndIconWrapper>
        <DatepickerInput
          autoFocus={autofocus}
          autoComplete="off"
          customInput={<input data-e2e={dataE2e} type="text" />}
          calendarContainer={CalendarContainer}
          dateFormat="P"
          disabled={disabled}
          excludeDates={excludeDates}
          filled={filled}
          filterDate={filterDate}
          fluid={fluid}
          highlightDates={highlightDates as any}
          id={name}
          includeDates={includeDates}
          isClearable={!disabled && clearable}
          maxDate={maxDate}
          minDate={minDate}
          name={name}
          onBlur={onBlur}
          onCalendarClose={onCalendarClose}
          onChange={handleChange}
          onInputClick={onInputClick}
          open={open}
          placeholderText={placeholder}
          portalId={portalId}
          ref={datepicker => {
            inputEl = datepicker || null
          }}
          selected={isArrayValue ? startDate : innerValue} // innerValue is Date when startDate is undefined.
          startDate={startDate}
          endDate={endDate}
          selectsEnd={selectsEnd}
          selectsRange={withRange}
          showPopperArrow={false}
          variant={variant}
          withPortal={withPortal}
        />

        {(disabled || !filled) && (
          <IconContainer data-e2e="icon-container" disabled={disabled}>
            <Icon
              faStyle="fas"
              name={icon}
              onClick={() => (inputEl ? inputEl.setFocus() : undefined)}
              variant="neutral"
            />
          </IconContainer>
        )}
      </DatepickerAndIconWrapper>

      {!!errorMessage && (
        <Message data-e2e="datepicker-error-message" type="error" value={errorMessage} />
      )}
      {!!warningMessage && !errorMessage && (
        <Message data-e2e="datepicker-warning-message" type="warning" value={warningMessage} />
      )}
    </DatepickerContainer>
  )
}

export default memo(Datepicker) as typeof Datepicker
