import styled from 'styled-components'

import Box from '../../../../layout/Box/Box'

export const ColumnConfigurationScrollingArea = styled(Box)`
  height: 400px;
  overflow-y: auto;
`

export const ResetButtonWrapper = styled(Box)`
  text-align: center;
`
