import styled from 'styled-components'

import { type Variant } from '../../../themes'
import { inputStyle } from '../Input/Input.styles'

export const TextareaField = styled.textarea<{
  disabled: boolean
  filled: boolean
  variant?: Variant
}>`
  ${inputStyle}
  height: initial;
  font-size: ${props => props.theme.fontSizes.medium};
  width: 100%;
`

export const TextareaContainer = styled('div')<{ fluid?: boolean }>`
  position: relative;
  display: inline-block;
  width: ${props => (props.fluid ? '100%' : '15em')};
`
