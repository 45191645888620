import styled from 'styled-components'
import type { ModalProps } from 'styled-react-modal'
import Modal from 'styled-react-modal'

import type { ThemeProviderProps, Width } from '../../../themes'

type ExtendedModalProps = {
  className?: string
  fluid?: boolean
  role: 'dialog'
  width?: Width
}

export type CustomModalProps = ThemeProviderProps & ModalProps & ExtendedModalProps

export const ModalHeader = styled.div`
  padding: ${props => props.theme.spacingsInsetSquish.large};
  border-bottom: 1px solid ${props => props.theme.colors.secondary.lighter};
`

export const ModalContent = styled.div`
  height: 100%;
  overflow-y: auto;
  padding: ${props => props.theme.spacingsInsetSquish.large};
`

export const ModalFooter = styled.div`
  padding: ${props => props.theme.spacingsInsetSquish.large};
  border-top: 1px solid ${props => props.theme.colors.secondary.lighter};
`

export const StyledModal = Modal.styled`
  background-color: white;
  border-radius: ${(props: CustomModalProps) => props.theme.dimensions?.borderRadius};
  display: flex;
  flex-direction: column;
  max-height: 75vh;
  min-width: ${(props: CustomModalProps) => (props.fluid ? '100%' : 'inherit')};
  width: ${(props: CustomModalProps) => props.width ?? 'initial'};
`
