import React, { memo } from 'react'

import type { Size } from '../../../themes/types'
import { StyledDivider } from './Divider.styles'

export interface DividerProps {
  'data-e2e'?: string
  bottomSpacing?: Size
  className?: string
  sideSpacing?: Size
  topSpacing?: Size
  verticalHeight?: Size
}

const Divider = ({
  'data-e2e': dataE2e,
  bottomSpacing,
  className,
  sideSpacing,
  topSpacing,
  verticalHeight,
}: DividerProps) => (
  <StyledDivider
    bottomSpacing={bottomSpacing}
    className={className}
    data-e2e={dataE2e}
    sideSpacing={sideSpacing}
    topSpacing={topSpacing}
    verticalHeight={verticalHeight}
  >
    <div />
  </StyledDivider>
)

export default memo(Divider)
