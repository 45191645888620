import { Toaster } from 'sonner'
import styled from 'styled-components'

export const StyledToaster = styled(Toaster)`
  :where([data-sonner-toast]) {
    &.toast-success {
      background-color: ${props => props.theme.colors.success.lighter};
      color: ${props => props.theme.colors.success.base};
      border: 1px solid ${props => props.theme.colors.success.base};

      :where([data-icon]) {
        color: ${props => props.theme.colors.success.base};
      }
    }

    &.toast-error {
      background-color: ${props => props.theme.colors.danger.lighter};
      color: ${props => props.theme.colors.danger.base};
      border: 1px solid ${props => props.theme.colors.danger.base};

      :where([data-icon]) {
        color: ${props => props.theme.colors.danger.base};
      }
    }

    &.toast-warning {
      background-color: ${props => props.theme.colors.warning.lighter};
      color: ${props => props.theme.colors.warning.base};
      border: 1px solid ${props => props.theme.colors.warning.base};

      :where([data-icon]) {
        color: ${props => props.theme.colors.warning.base};
      }
    }

    &.toast-info {
      background-color: ${props => props.theme.colors.primary.lighter};
      color: ${props => props.theme.colors.primary.base};
      border: 1px solid ${props => props.theme.colors.primary.base};

      :where([data-icon]) {
        color: ${props => props.theme.colors.primary.base};
      }
    }
  }
`
