import { sizes } from './commons.type'

const [, ...selectedSizes] = sizes

export const fontWeights = ['light', 'regular', 'bold'] as const
export type FontWeight = (typeof fontWeights)[number]
export const fontSizes = [...selectedSizes, 'xxlarge', 'xxxlarge'] as const
export type FontSize = (typeof fontSizes)[number]

export interface Typography {
  fontFamilies: string
  fontWeights: {
    [key in FontWeight]: number | string
  }
  fontSizes: {
    [key in FontSize]: string
  }
}
