import React, { type ReactNode, useCallback } from 'react'
import { useTheme } from 'styled-components'

import { useBreakpoints } from '../../../providers'
import type { Threshold } from '../../../themes/types'
import { StyledContainer } from './Container.styles'

export interface ContainerProps {
  'data-e2e'?: string
  children?: ReactNode
  className?: string
  fixedBreakpoint?: Threshold
  flex?: boolean
  fluid?: boolean
  noGutters?: boolean
}

const Container = ({
  'data-e2e': dataE2e,
  children,
  className,
  fixedBreakpoint,
  flex = false,
  fluid = false,
  noGutters = false,
}: ContainerProps) => {
  const { activeBreakpoint } = useBreakpoints()
  const { breakpoints } = useTheme()

  const getContainerWidth = useCallback(() => {
    if (fixedBreakpoint) {
      return breakpoints[fixedBreakpoint].container
    }

    switch (activeBreakpoint) {
      case 'isMobile':
        return breakpoints.mobile.container
      case 'isTablet':
        return breakpoints.tablet.container
      case 'isSmallDesktop':
        return breakpoints.smallDesktop.container
      case 'isMediumDesktop':
        return breakpoints.mediumDesktop.container
      case 'isLargeDesktop':
        return breakpoints.largeDesktop.container
      default:
        return breakpoints.mediumDesktop.container
    }
  }, [breakpoints, fixedBreakpoint, activeBreakpoint])

  return (
    <StyledContainer
      data-e2e={dataE2e}
      size={getContainerWidth()}
      flex={flex}
      fluid={fluid}
      noGutters={noGutters}
      className={className}
    >
      {children}
    </StyledContainer>
  )
}

export default Container
