import type { ReactNode } from 'react'
import React from 'react'
import type { Row as TableRow } from 'react-table'

import { CardRow, Cell, Row, VirtualContainer, VirtualItemsContainer } from '../Table.styles'
import useVirtualizer from '../useVirtualizer'

interface VirtualizedBodyProps {
  children?: ReactNode
  count: number
  estimatedSize: number
  onClick?: (id: number) => void
  paddingEnd?: number
  prepareRow: (row: TableRow) => void
  rows: TableRow[]
}

const VirtualizedBody = ({
  children,
  count,
  estimatedSize,
  onClick,
  paddingEnd,
  prepareRow,
  rows,
}: VirtualizedBodyProps) => {
  const { items, measureElement, parentRef, totalSize, translateY } = useVirtualizer({
    count,
    estimatedSize,
  })

  return (
    <div ref={parentRef}>
      <VirtualContainer height={totalSize}>
        <VirtualItemsContainer paddingEnd={paddingEnd} translateY={translateY}>
          {items.map(virtualRow => {
            const row: any = rows[virtualRow.index]
            prepareRow(row)
            const { key, ...restRowProps } = row.getRowProps()

            return (
              <CardRow
                data-index={virtualRow.index}
                data-row-disabled={(row.state.opacity ?? 1) !== 1}
                data-row-selected={!!row.state.isEdited}
                isEdited={!!row.state.isEdited}
                key={virtualRow.key}
                onClick={() => (onClick ? onClick(row.original.id) : null)}
                opacity={row.state.opacity}
                ref={measureElement}
                spacing="small"
                variant={row.state.variant}
              >
                <Row key={key} {...restRowProps}>
                  {row.cells.map((cell: any, cellIndex: number) => (
                    <Cell
                      {...cell.getCellProps()}
                      bold={cell.column.bold}
                      align={cell.column.align || 'left'}
                      key={cellIndex}
                    >
                      {cell.render('Cell')}
                    </Cell>
                  ))}
                </Row>
              </CardRow>
            )
          })}

          {children}
        </VirtualItemsContainer>
      </VirtualContainer>
    </div>
  )
}

export default VirtualizedBody
