import styled from 'styled-components'

import { getForegroundColor } from '../../../themes/helpers'
import type { AdvancedColors, Variant } from '../../../themes/types'
import Icon from '../Icon/Icon'

export type Type = 'button' | 'reset' | 'submit'
export type Sticky = 'sticky-bottom' | 'sticky-left' | 'sticky-right' | 'sticky-top'

interface Props {
  $reversed?: boolean
  'data-e2e'?: string
  disabled?: boolean
  fluid?: boolean
  form?: string
  hasChildren: boolean
  hasInputHeight?: boolean
  onClick?: () => void
  outline?: boolean
  sticky?: Sticky
  text?: boolean
  type?: Type
  variant: Variant
}

const getBackgroundColor = (
  colors: AdvancedColors,
  variant: Variant,
  outline?: boolean,
  text?: boolean,
  disabled?: boolean
) => {
  if (outline || text) return 'transparent'
  if (disabled) return colors[variant].base

  return colors[variant].dark
}

export const StyledIconContainer = styled.div`
  padding: 0.7rem;
`

export const StyledButtonIcon = styled(Icon)<{ iconVariant: Variant; isOutline: boolean }>`
  color: ${props =>
    props.isOutline
      ? props.theme.colors[props.iconVariant].base
      : getForegroundColor(props.iconVariant, props.theme)};
`
export const StyledButtonText = styled.div<{ underline: boolean }>`
  padding: 0.7rem;
  text-decoration: ${props => (props.underline ? 'underline' : 'none')};
`

const StyledButtonContainer = styled.button<Props>`
  flex-shrink: 0;
  position: relative;
  outline: none;
  border: none;
  appearance: none;
  line-height: 1.15;
  padding: ${props => (props.hasInputHeight ? '0 .5rem' : '0rem')};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
  flex-direction: ${props => (props.$reversed ? 'row-reverse' : 'row')};
  border-radius: ${props => props.theme.dimensions.borderRadius};
  min-width: ${props => (props.fluid ? '100%' : '2.5rem')};
  font-family: ${props => props.theme.fontFamilies};
  font-weight: ${props => props.theme.fontWeights.bold};
  opacity: ${props => props.disabled && props.theme.opacities.disabled};
  color: ${props =>
    props.outline || props.text
      ? props.theme.colors[props.variant].base
      : getForegroundColor(props.variant, props.theme)};
  background-color: ${props =>
    props.outline || props.text ? 'transparent' : props.theme.colors[props.variant].base};
  box-shadow: ${props =>
    props.outline && `inset 0 0 0 1px ${props.theme.colors[props.variant].base}`};
  height: ${props => (props.hasInputHeight ? props.theme.dimensions.inputHeight : 'auto')};

  &:hover {
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    background-color: ${props =>
      getBackgroundColor(
        props.theme.colors,
        props.variant,
        props.outline,
        props.text,
        props.disabled
      )};
    color: ${props =>
      (props.disabled && (props.outline || props.text) && props.theme.colors[props.variant].base) ||
      ((props.outline || props.text) && props.theme.colors[props.variant].dark) ||
      getForegroundColor(props.variant, props.theme)};
    box-shadow: ${props =>
      props.outline && `inset 0 0 0 1px ${props.theme.colors[props.variant].dark}`};
  }

  & > *:not(:first-child) {
    padding-left: ${props => (props.$reversed ? '0.7rem' : 0)};
    padding-right: ${props => (props.$reversed ? 0 : '0.7rem')};
  }

  ${props =>
    (props.sticky === 'sticky-top' &&
      `
        border-radius: 0 0 ${props.theme.dimensions.borderRadius} ${props.theme.dimensions.borderRadius};
        margin-top: 0;
      `) ||
    (props.sticky === 'sticky-right' &&
      `
        border-radius: ${props.theme.dimensions.borderRadius} 0 0 ${props.theme.dimensions.borderRadius};
        margin-right: 0;
      `) ||
    (props.sticky === 'sticky-bottom' &&
      `
      border-radius: ${props.theme.dimensions.borderRadius} ${props.theme.dimensions.borderRadius} 0 0;
      margin-bottom: 0;
    `) ||
    (props.sticky === 'sticky-left' &&
      `
      border-radius:  0 ${props.theme.dimensions.borderRadius} ${props.theme.dimensions.borderRadius} 0;
      margin-left: 0;
    `)}
`

export default StyledButtonContainer
