import styled from 'styled-components'

import Stack from '../../layout/Stack/Stack'

export const DataTableWrapper = styled(Stack)<{ isFullscreen: boolean }>`
  background-color: ${props => props.theme.colors.neutral.lighter};

  ${props =>
    props.isFullscreen &&
    `position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: ${props.theme.spacings.medium};
    z-index: 1001;
    `}
`
