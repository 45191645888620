import styled from 'styled-components'

import type { PageContentProps } from './PageContent'

export const StyledPageContent = styled.div<PageContentProps & { isAlongsideBurgerMenu: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: calc(
    100% -
      ${props => (props.isAlongsideBurgerMenu ? '0px' : props.theme.menu.dimensions.desktop.width)}
  );
  padding-bottom: ${props => props.theme.spacings.medium};

  ${props =>
    props.isAlongsideBurgerMenu &&
    `padding: ${props.theme.menu.dimensions.mobile.topBarHeight} 0 ${props.theme.spacings.medium} 0 !important;
    min-height: 100vh;
      &::-webkit-scrollbar {
    display: none;
  }`}
`
