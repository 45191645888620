import React from 'react'

import { DataTableProvider } from '@procsea/design-system'

const getRowCountText = (rowCount: number) =>
  interpolate(
    ngettext('%(rowCount)s row', '%(rowCount)s rows', rowCount),
    { rowCount: rowCount.toString() },
    true
  )

interface ConfiguredDataTableProviderProps {
  children: React.ReactNode
}

const ConfiguredDataTableProvider = ({ children }: ConfiguredDataTableProviderProps) => (
  <DataTableProvider
    actionBarFeedbackButtonLabel={gettext('Tell Us What You Think')}
    actionMenuButtonLabel={gettext('Actions')}
    actionMenuTitle={gettext('Actions')}
    configMenuOpenButtonLabel={gettext('Configuration')}
    configMenuTitle={gettext('Table configuration')}
    configMenuPinnedColumnSubTitle={gettext('Pinned columns')}
    configMenuRemainingColumnSubTitle={gettext('Remaining columns')}
    configMenuResetButtonLabel={gettext('Reset Table')}
    countFetchingText={gettext('Fetching...')}
    getRowCountText={getRowCountText}
    configMenuPinColumnButtonTooltip={gettext('Pin column')}
  >
    {children}
  </DataTableProvider>
)

export default ConfiguredDataTableProvider
