import type { IconPrefix } from '@fortawesome/pro-solid-svg-icons'
import React, { memo, useMemo } from 'react'

import type { Variant } from '../../../themes'
import Badge from '../Badge/Badge'
import Tooltip from '../Tooltip/Tooltip'
import { IconBackground, StyledIcon, StyledStackedIconWrapper } from './Icon.styles'
import type { IconSize, Transform, UsedIcons } from './icons'
import { formatTransform } from './icons'

export type IconName = UsedIcons

export interface IconProps {
  'data-e2e'?: string
  backgroundVariant?: Variant
  badgeCount?: number
  bounce?: boolean
  className?: string
  tooltip?: React.ReactNode
  faStyle?: IconPrefix
  hasDot?: boolean
  hasPointer?: boolean
  name: IconName
  onClick?: (e?: React.MouseEvent<Element, MouseEvent>) => void
  size?: IconSize
  title?: string
  transform?: Transform
  variant?: Variant
}

const Icon = ({
  'data-e2e': dataE2e,
  backgroundVariant,
  badgeCount = 0,
  bounce,
  className,
  faStyle = 'fal',
  hasDot = false,
  hasPointer = false,
  name,
  onClick,
  size = '1x',
  title,
  tooltip,
  transform,
  variant = 'black',
}: IconProps) => {
  const formattedTransform = useMemo(() => formatTransform(transform), [transform])
  const dotTransform = useMemo(() => formatTransform({ up: 6, right: 6, shrink: 8 }), [])
  const hasLayer = hasDot || badgeCount > 0

  const renderIcon = () =>
    hasLayer ? (
      <StyledStackedIconWrapper className={`fa-layers fa-fw fa-${size}`}>
        <StyledIcon
          bounce={bounce}
          className={className}
          data-e2e={dataE2e}
          $hasPointer={hasPointer}
          icon={[faStyle, name]}
          name={name}
          title={title}
          transform={formattedTransform}
          variant={variant}
        />
        {hasDot ? (
          <StyledIcon
            data-e2e={`${dataE2e}--dot`}
            $hasPointer={hasPointer}
            icon={['fas', 'circle']}
            name="circle"
            transform={dotTransform}
            variant="danger"
          />
        ) : (
          <Badge data-e2e={`${dataE2e}--counter`} content={badgeCount} floating variant="danger" />
        )}
      </StyledStackedIconWrapper>
    ) : (
      <StyledIcon
        bounce={bounce}
        className={className}
        data-e2e={dataE2e}
        icon={[faStyle, name]}
        name={name}
        onClick={onClick}
        $hasPointer={hasPointer}
        size={size}
        title={title}
        transform={formattedTransform}
        variant={variant}
      />
    )

  const renderIconWithBackground = () =>
    backgroundVariant ? (
      <IconBackground backgroundVariant={backgroundVariant} onClick={onClick}>
        {renderIcon()}
      </IconBackground>
    ) : (
      renderIcon()
    )

  const renderIconWithTooltip = () =>
    tooltip ? (
      <Tooltip
        data-e2e={`${dataE2e}--tooltip`}
        tooltip={tooltip}
        variant="black"
        placement="top"
        trigger="hover"
      >
        {renderIconWithBackground()}
      </Tooltip>
    ) : (
      renderIconWithBackground()
    )

  return renderIconWithTooltip()
}

export default memo(Icon)
