import { type Content, Portal, Root, Trigger } from '@radix-ui/react-popover'
import type { ComponentPropsWithoutRef, ElementRef, ReactNode } from 'react'
import React, { forwardRef } from 'react'

import type { Variant } from '../../../themes'
import Icon from '../Icon/Icon'
import { StyledArrow, StyledClose, StyledContent } from './Popover.styles'

export interface PopoverProps {
  children?: ReactNode
  modal?: boolean
  open?: boolean
  onOpenChange?: React.Dispatch<boolean>
}

const Popover = ({ children, ...props }: PopoverProps) => <Root {...props}>{children}</Root>

type PopoverContentProps = ComponentPropsWithoutRef<typeof Content> & {
  children?: ReactNode
  'data-e2e'?: string
  variant?: Variant
}

const PopoverContent = forwardRef<ElementRef<typeof Content>, PopoverContentProps>(
  ({ children, 'data-e2e': dataE2e, variant = 'white', ...props }, ref) => (
    <Portal>
      <StyledContent
        $variant={variant}
        data-e2e={`${dataE2e}-content`}
        data-slot="popover-content"
        onOpenAutoFocus={event => event.preventDefault()}
        ref={ref}
        {...props}
      >
        {children}

        <StyledClose asChild>
          <Icon
            data-e2e={`${dataE2e}-popover-close-button`}
            faStyle="fas"
            name="xmark"
            size="lg"
            variant="neutral"
          />
        </StyledClose>

        <StyledArrow $variant={variant} />
      </StyledContent>
    </Portal>
  )
)

const PopoverTrigger = Trigger

export { Popover, PopoverContent, PopoverTrigger }
