import styled, { css } from 'styled-components'

import { getForegroundColor } from '../../../themes/helpers'
import type { FontSize, Variant } from '../../../themes/types'
import { type BadgePosition } from './Badge'

type BadgeProps = {
  floating: boolean
  fontVariant?: Variant
  position?: BadgePosition
  size: FontSize
  variant: Variant
}

export const StyledBadge = styled.div<BadgeProps>`
  display: inline-block;
  font-size: ${props => props.theme.fontSizes[props.size]};
  line-height: 1;
  vertical-align: baseline;
  background-image: none;
  font-weight: ${props => props.theme.fontWeights.bold};
  border: 0 solid transparent;
  padding: ${props => props.theme.spacingsInsetSquish.xsmall};
  border-radius: ${props => props.theme.dimensions.borderRadius};
  color: ${props =>
    props.fontVariant
      ? props.theme.colors[props.fontVariant].base
      : getForegroundColor(props.variant, props.theme)};
  background-color: ${props => props.theme.colors[props.variant].base};
  margin-right: ${props => (props.position === 'left' ? props.theme.spacings.small : '0')};
  margin-left: ${props => (props.position === 'right' ? props.theme.spacings.small : '0')};

  ${props =>
    props.floating &&
    css`
      position: absolute;
      z-index: 100;
      top: -0.6em;
      right: -0.6em;
      margin: 0 0 0 -1.5em !important;
    `}
`
