import type { ReactNode } from 'react'
import React from 'react'

import { Divider, Text } from '../../../core'
import { Inline } from '../../../layout'
import Box from '../../../layout/Box/Box'
import { StyledBackButton, StyledHeaderTitle } from './SlidingPanelHeader.styles'

export interface SlidingPanelHeaderProps {
  'data-e2e'?: string
  children?: ReactNode
  onClose: () => void
}
const SlidingPanelHeader = ({
  'data-e2e': dataE2e,
  children,
  onClose,
}: SlidingPanelHeaderProps) => (
  <>
    <Box data-e2e={`${dataE2e}--header`} inset="medium">
      <Inline spacing="medium" fluid>
        <StyledBackButton
          data-e2e={`${dataE2e}--back-button`}
          onClick={onClose}
          icon="arrow-left"
          text
          variant="neutral"
        />
        {typeof children === 'string' ? (
          <Text heading variant="secondary" size="xlarge">
            {children}
          </Text>
        ) : (
          <StyledHeaderTitle>{children}</StyledHeaderTitle>
        )}
      </Inline>
    </Box>
    <Divider />
  </>
)

export default SlidingPanelHeader
