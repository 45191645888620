/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import type { IconPrefix } from '@fortawesome/pro-solid-svg-icons'
import React, { memo, useState } from 'react'

import { useEffectAfterMount } from '../../../hooks'
import type { Variant } from '../../../themes'
import type { IconName } from '../../core'
import { Badge, Icon } from '../../core'
import { Inline } from '../../layout'
import type { FormComponent } from '../interfaces'
import { CheckboxInput, ToggleContainer } from './Toggle.styles'

export interface ToggleProps extends FormComponent<boolean> {
  count?: number
  countVariant?: Variant
  icon?: IconName
  iconStyle?: IconPrefix
  iconVariant?: Variant
}

const Toggle = ({
  'data-e2e': dataE2e,
  className,
  count,
  countVariant = 'neutral',
  disabled = false,
  icon,
  iconStyle = 'fas',
  iconVariant = 'black',
  label,
  name,
  onChange,
  value,
}: ToggleProps) => {
  const [checked, setChecked] = useState(!!value)

  useEffectAfterMount(() => setChecked(!!value), [value])

  const handleChange = () => {
    if (!disabled) {
      setChecked(!checked)
      onChange?.({ name, value: !checked })
    }
  }

  return (
    <ToggleContainer checked={checked} className={className} data-e2e={dataE2e} disabled={disabled}>
      <CheckboxInput
        data-e2e="toggle"
        defaultChecked={checked}
        disabled={disabled}
        id={name}
        name={name}
      />
      <label htmlFor={name} onClick={handleChange}>
        <Inline alignment="center" spacing="small">
          {!!icon && <Icon faStyle={iconStyle} name={icon} variant={iconVariant} />}

          {!!label && <span>{label}</span>}

          {!!count && (
            <Badge content={count} data-e2e={`${dataE2e}--count`} variant={countVariant} />
          )}
        </Inline>
      </label>
    </ToggleContainer>
  )
}

export default memo(Toggle)
