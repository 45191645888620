import styled, { css } from 'styled-components'

import { RadioContainer } from '../Radio/Radio.styles'
import type { RadioFormatType } from './context/RadioGroupContext'

export const RadioGroupContainer = styled.div<{
  collapsed: boolean
  fluid: boolean
  format: RadioFormatType
  noWrap?: boolean
  reversed: boolean
}>`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: ${props => (props.reversed ? 'flex-end' : 'flex-start')};
  ${props => props.noWrap && 'overflow-x: auto;'}

  ${RadioContainer} {
    ${props =>
      props.fluid &&
      `
        flex: 1 0 auto;
        text-align: center;
        
        &:last-child {
          margin-right: 0;
        }
    `};
  }

  ${props =>
    props.collapsed &&
    props.format === 'toggled' &&
    css`
      ${RadioContainer} {
        label {
          border-radius: 0;

          &:hover {
            z-index: 1;
          }
        }

        &:not(:first-of-type) label {
          margin-left: -1px;
        }

        &:first-child label {
          border-radius: ${props =>
            `${props.theme.dimensions.borderRadius} 0 0 ${props.theme.dimensions.borderRadius}`};
        }
        &:last-child label {
          border-radius: ${props =>
            `0 ${props.theme.dimensions.borderRadius} ${props.theme.dimensions.borderRadius} 0`};
        }
      }
    `}
`
