import Async from 'react-select/async'
import type { AsyncProps } from 'react-select/async'
import styled from 'styled-components'

import type { StyledSelectProps } from '../Select/Select.styles'
import { mixinSelectStyle } from '../Select/Select.styles'

export const StyledAsyncSelect: React.ComponentType<
  AsyncProps<unknown, boolean, any> & StyledSelectProps
> = styled(Async).attrs<StyledSelectProps>(({ disabled }) => ({
  disabled,
}))<StyledSelectProps>`
  ${mixinSelectStyle}
`
