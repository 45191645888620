import isEqual from 'lodash/isEqual'
import React, { useEffect, useState } from 'react'
import type { CSSObjectWithLabel, MultiValue, Options } from 'react-select'
import styled from 'styled-components'

import { Button, ContextMenu } from '../../core'
import { Box, Flex, Stack } from '../../layout'
import Label from '../Label/Label'
import Radio from '../Radio/Radio'
import RadioGroup from '../RadioGroup/RadioGroup'
import type { ValueType } from '../RadioGroup/context/RadioGroupContext'
import Select from '../Select/Select'
import type { MyOptionType } from '../Select/Select.types'
import type { FormComponent, FormOnChange, FormOnChangeParams } from '../interfaces'

type InnerSelectValue = number[] | string[]

type InnerValue = {
  includes: string
  selectedItems: InnerSelectValue
}

export interface IncludeExcludeSelectProps extends FormComponent<InnerSelectValue> {
  applyButtonLabel: string
  excludesButtonLabel: string
  fluid?: boolean
  includesButtonLabel: string
  innerSelectPlaceholder?: string
  onReset: () => void
  options: Options<MyOptionType<string | number>>
  referenceSelectPlaceholder?: string
  resetButtonLabel: string
}

const StyledBox = styled(Box)`
  width: 300px;
`

const IncludeExcludeSelect = ({
  'data-e2e': dataE2e,
  applyButtonLabel,
  errorMessage,
  excludesButtonLabel,
  fluid,
  includesButtonLabel,
  innerSelectPlaceholder,
  label,
  name,
  onChange,
  onReset,
  options,
  referenceSelectPlaceholder,
  resetButtonLabel,
  value = [],
}: IncludeExcludeSelectProps) => {
  const defaultInnerValue = { includes: 'includes', selectedItems: [] }

  const [innerValue, setInnerValue] = useState<InnerValue>(defaultInnerValue)
  const [isOpen, setIsOpen] = useState(false)
  const [referenceValue, setReferenceValue] = useState<MultiValue<string | number>>()
  const [selectHasError, setSelectHasError] = useState(false)

  const { selectedItems } = innerValue
  const noItemSelected = selectedItems.length < 1
  const isIncludes = innerValue.includes === 'includes'
  const payload = selectedItems.map(id => (isIncludes ? id : -id)) as InnerSelectValue

  const handleRadioChange = ({ value }: FormOnChangeParams<ValueType>) => {
    if (value === 'includes') return setInnerValue({ ...innerValue, includes: 'includes' })
    if (value === 'excludes') return setInnerValue({ ...innerValue, includes: 'excludes' })
    return null
  }

  const handleOnSelectChange: FormOnChange<MultiValue<string | number>> = ({ value }) => {
    setInnerValue({
      ...innerValue,
      selectedItems: value as InnerSelectValue,
    })
  }

  const handleChange = () => {
    setIsOpen(false)
    setSelectHasError(noItemSelected)
    setReferenceValue([innerValue.includes, ...selectedItems])
    if (selectedItems.length >= 1) onChange?.({ name, value: payload })
  }

  const handleReset = () => {
    setIsOpen(false)
    setInnerValue(defaultInnerValue)
    setSelectHasError(false)
    setReferenceValue([])
    onReset()
  }

  useEffect(() => {
    if (!isEqual(value, payload)) {
      setInnerValue(defaultInnerValue)
      setSelectHasError(false)
      setReferenceValue([])
    }
  }, [JSON.stringify({ value })])

  const referenceOptions = [
    { label: '=', value: 'includes' },
    { label: '≠', value: 'excludes' },
    ...options,
  ]

  const handleVisibilityChange = () => setIsOpen(!isOpen)

  const reference = (
    <div onClick={handleVisibilityChange} onTouchStart={handleVisibilityChange} aria-hidden>
      <Select
        data-e2e={`include-exclude--${dataE2e}`}
        errorMessage={selectHasError && !isOpen ? errorMessage : undefined}
        fluid={fluid}
        maxVisibleSelectedOptions={2}
        menuIsOpen={false}
        multiple
        name={name}
        options={referenceOptions}
        placeholder={referenceSelectPlaceholder}
        styles={{
          control: base => base,
          multiValueRemove: base => ({ ...base, display: 'none' }) as CSSObjectWithLabel,
          multiValueLabel: base => ({ ...base, paddingRight: 6 }) as CSSObjectWithLabel,
        }}
        value={referenceValue}
      />
    </div>
  )

  return (
    <Stack>
      {!!label && <Label text={label} />}
      <ContextMenu
        reference={reference}
        open={isOpen}
        placement="bottom-start"
        onVisibilityChange={handleVisibilityChange}
      >
        <StyledBox data-e2e="box" inset="large">
          <Stack spacing="large">
            <RadioGroup
              data-e2e="radiogroup"
              format="toggled"
              name="documents"
              collapsed
              onChange={handleRadioChange}
              value={innerValue.includes}
              required
            >
              <Radio content={includesButtonLabel} key="includes" value="includes" />
              <Radio content={excludesButtonLabel} key="excludes" value="excludes" />
            </RadioGroup>
            <Select
              data-e2e="inner-select"
              multiple
              options={options}
              fluid
              onChange={handleOnSelectChange}
              value={selectedItems}
              errorMessage={selectHasError && isOpen && noItemSelected ? errorMessage : undefined}
              placeholder={innerSelectPlaceholder}
            />
            <Flex>
              <Button data-e2e="reset-button" onClick={handleReset} variant="danger" outline>
                {resetButtonLabel}
              </Button>
              <Button data-e2e="apply-button" onClick={handleChange} variant="success">
                {applyButtonLabel}
              </Button>
            </Flex>
          </Stack>
        </StyledBox>
      </ContextMenu>
    </Stack>
  )
}

export default IncludeExcludeSelect
