import React, { memo } from 'react'

import Container from '../../layout/Container/Container'
import type { NavTabItemProps } from './NavTabItem/NavTabItem'
import NavTabItem from './NavTabItem/NavTabItem'
import { NavTabsContent, StyledNavTabs } from './NavTabs.styles'

export interface NavTabsProps {
  tabs: NavTabItemProps[]
  onClick?: () => void
}

const NavTabs = ({ tabs = [], onClick }: NavTabsProps) => (
  <StyledNavTabs>
    <NavTabsContent>
      <Container flex>
        {tabs.map(tab => (
          <NavTabItem key={tab.title} {...tab} onClick={onClick} />
        ))}
      </Container>
    </NavTabsContent>
  </StyledNavTabs>
)

export default memo(NavTabs)
