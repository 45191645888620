import React, { type ReactNode, forwardRef } from 'react'

import type { Size } from '../../../themes/types'
import { StyledStack } from './Stack.styles'

export interface StackProps {
  'data-e2e'?: string
  alignment?: 'stretch' | 'flex-start' | 'center' | 'flex-end'
  /** @deprecated use alignment prop instead */
  center?: boolean
  children?: ReactNode
  className?: string
  fullWidth?: boolean
  inline?: boolean
  spacing?: Size
  style?: React.CSSProperties
}

const Stack = forwardRef<HTMLDivElement, StackProps>(({ children, ...props }, ref) => (
  <StyledStack {...props} ref={ref}>
    {children}
  </StyledStack>
))

export default Stack
