import React from 'react'
import type { GroupBase, MenuProps } from 'react-select'
import { components } from 'react-select'

import { SelectMenu, StyledCreateButton, StyledCreateMenuHeader } from './Menu.styles'

const Menu = <OptionType, isMulti extends boolean>({
  children,
  ...props
}: MenuProps<OptionType, isMulti, GroupBase<OptionType>>) => (
  <SelectMenu rightPosition={props.selectProps.rightPosition}>
    <components.Menu {...props}>
      {typeof props.selectProps.onCreate === 'function' && (
        <StyledCreateMenuHeader>
          <StyledCreateButton
            data-e2e="select-create-button"
            onClick={props.selectProps.onCreate}
            text
            underline
            variant="primary"
          >
            {props.selectProps.createLabel}
          </StyledCreateButton>
        </StyledCreateMenuHeader>
      )}
      {children}
    </components.Menu>
  </SelectMenu>
)

export default Menu
