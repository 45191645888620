import styled, { css } from 'styled-components'

import type { Variant, Width } from '../../../themes'
import { inputStyle } from '../Input/Input.styles'
import Select from '../Select/Select'

type InputProps = {
  disabled: boolean
  filled?: boolean
  fluid?: boolean
  inputWidth: Width
  variant?: Variant
}

export const StyledInputNumber = styled.input<InputProps>`
  &&&& {
    ${inputStyle}
    ${props => props.fluid && 'width: 100%;'}
    ${props => props.fluid && 'flex: 1;'}
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &&&&::-webkit-outer-spin-button,
  &&&&::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;
  z-index: 1;
  width: ${props => props.inputWidth};
`

export const StyledSelectUnit = styled(Select)`
  margin-left: -2px;
  & {
    .Select__control,
    .Select__control.Select__control--menu-is-open {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      background-color: ${props => props.theme.colors.neutral.lighter};
      padding: 0 ${props => props.theme.spacings.small};
    }
    .Select__control.Select__control--menu-is-open {
      z-index: 2;
    }
  }

  & .Select__single-value {
    padding-bottom: 2px;
  }

  & .Select__dropdown-indicator {
    color: ${props => props.theme.colors.black.base};
  }
` as typeof Select

export const StyledSingleOption = styled.div<{ disabled?: boolean }>`
  background-color: ${props => props.theme.colors.white.base};
  border: 1px solid ${props => props.theme.colors.neutral.base};
  border-left: none;
  border-bottom-right-radius: ${props => props.theme.dimensions.borderRadius};
  border-top-right-radius: ${props => props.theme.dimensions.borderRadius};
  box-sizing: border-box;
  height: ${props => props.theme.dimensions.inputHeight};
  padding: ${props => props.theme.spacings.small} calc(1.5 * ${props => props.theme.spacings.small});
  color: ${props => props.theme.colors.black.base};
  align-content: center;

  ${props =>
    props.disabled &&
    css`
      background-color: ${props.theme.colors.white.base};
      opacity: ${props.theme.opacities.disabled};
      border-color: ${props.theme.colors.secondary.lighter};
      box-shadow: none;

      &:hover {
        border-color: ${props.theme.colors.secondary.lighter};
      }
    `}
`

export const InputSelectContainer = styled.div`
  position: relative;
`
