import type { ReactNode } from 'react'
import React from 'react'

import { StyledMenuFooter } from './MenuFooter.styles'

export interface MenuFooterProps {
  'data-e2e'?: string
  children?: ReactNode
}

const MenuFooter = ({ 'data-e2e': dataE2e = 'menu-footer', children }: MenuFooterProps) => (
  <StyledMenuFooter data-e2e={dataE2e}>{children}</StyledMenuFooter>
)

export default MenuFooter
