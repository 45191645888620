import styled from 'styled-components'

import { DEFAULT_ROW_HEIGHT } from '../DataTable.constants'

export const StyledTable = styled.table`
  border-spacing: 0;
  background-color: ${props => props.theme.colors.white.base};
  width: calc((var(--table-size) * 1px) + (var(--header-last-column-size) * 1px));
  display: grid;
`

export const HorizontalScrollingArea = styled.div`
  padding-right: ${props => props.theme.spacings.medium};
  margin-left: ${props => props.theme.spacings.medium};
  max-width: calc(100% - ${props => props.theme.spacings.medium});
  width: calc(100% - ${props => props.theme.spacings.medium});
  overflow-y: auto;
  overscroll-behavior: contain;
  margin-bottom: -${props => props.theme.spacings.medium};
`

export const TableWrapper = styled.div`
  background-color: ${props => props.theme.colors.white.base};
`

export const TableHeader = styled.thead`
  font-weight: ${props => props.theme.fontWeights.bold};
  background-color: ${props => props.theme.colors.white.base};
  z-index: 1;
  color: ${props => props.theme.colors.black.base};
  position: sticky;
  top: 0;
  min-height: ${DEFAULT_ROW_HEIGHT}px;
`

export const TableRow = styled.tr`
  display: flex;
  &: hover {
    background-color: ${props => props.theme.colors.primary.lightest};
  }

  min-height: ${DEFAULT_ROW_HEIGHT}px;
  height: ${DEFAULT_ROW_HEIGHT}px;
  &[data-row-disabled='true'] {
    opacity: 0.5;
  }
`

export const TableHead = styled.th`
  background-color: ${props => props.theme.colors.white.base};
  position: relative;
  display: flex;
  align-items: center;
  padding: ${props => props.theme.spacings.small};
  max-width: calc(var(--header-${props => props.id}-size) * 1px);
  min-width: calc(var(--header-${props => props.id}-size) * 1px);
  width: calc(var(--header-${props => props.id}-size) * 1px);
  border-bottom: 3px solid ${props => props.theme.colors.neutral.lighter};
  &:not(:last-child) {
    border-right: 2px solid ${props => props.theme.colors.neutral.lighter};
  }

  & > .table-head-label {
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
`

export const TableBody = styled.tbody`
  &: hover {
    background-color: ${props => props.theme.colors.primary.lightest};
  }
`

export const TableCell = styled.td<{ columnId: string }>`
  background-color: ${props => props.theme.colors.white.base};
  padding: ${props => props.theme.spacings.small};
  text-align: left;
  border-bottom: 2px solid ${props => props.theme.colors.neutral.lighter};
  width: calc(var(--col-${props => props.columnId}-size) * 1px);
  & > span {
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  display: flex;
  align-items: center;
  height: ${DEFAULT_ROW_HEIGHT}px;
  min-height: ${DEFAULT_ROW_HEIGHT}px;
  border-right: 2px solid ${props => props.theme.colors.white.base};
`
