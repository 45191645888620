import styled from 'styled-components'

const StyledColumnResizer = styled.div<{ isResizing: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 6px;
  background: ${props => props.theme.colors.neutral.light};
  cursor: col-resize;
  user-select: none;
  touch-action: none;

  ${props =>
    props.isResizing &&
    ` &&
    {
      background: ${props.theme.colors.neutral.base};
      opacity: 1;
    }
    `}
  @media
    (hover: hover) {
    & {
      opacity: 0;
    }

    *:hover > & {
      opacity: 1;
    }
  }
`

export const ColumnResizer = StyledColumnResizer
