import type { MutableRefObject } from 'react'
import { useLayoutEffect, useState } from 'react'

const useOnScreen = (ref: MutableRefObject<any>, rootMargin = '0px') => {
  const [isIntersecting, setIntersecting] = useState(false)

  useLayoutEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting)
      },
      {
        rootMargin,
      }
    )

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      observer.unobserve(ref.current)
    }
  }, [])

  return isIntersecting
}

export default useOnScreen
