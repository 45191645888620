import React, { memo } from 'react'

import type { SubNavTabItemProps } from './SubNavTabItem/SubNavTabItem'
import SubNavTabItem from './SubNavTabItem/SubNavTabItem'
import { StyledSubNavTabs } from './SubNavTabs.styles'

export interface SubNavTabsProps {
  hasTitle: boolean
  tabs: SubNavTabItemProps[]
  onClick?: () => void
}

const SubNavTabs = ({ hasTitle, tabs = [], onClick }: SubNavTabsProps) => (
  <StyledSubNavTabs hasTitle={hasTitle}>
    {tabs.map(tab => (
      <SubNavTabItem key={tab.title} {...tab} onClick={onClick} />
    ))}
  </StyledSubNavTabs>
)

export default memo(SubNavTabs)
