import styled from 'styled-components'

import { type QualityProps } from './Quality'

export const StyledQuality = styled.span<QualityProps>`
  display: inline-block;
  line-height: 1;
  min-width: 1rem;
  text-align: center;
  color: white;
  padding: ${props => `${props.theme.spacingsInsetSquish.small}`};
  border-radius: 500rem;
  font-size: ${props => props.theme.fontSizes.xsmall};
  background-color: ${props => props.theme.qualities[props.quality]};
  ${props => props.fixedSize && 'width: 2rem'};
`
