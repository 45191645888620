import React, { memo } from 'react'

import type { Variant } from '../../../themes'
import { StyledCounter } from './Counter.styles'

export interface CounterProps {
  'data-e2e'?: string
  count?: number
  variant?: Variant
}

const Counter = ({ 'data-e2e': dataE2e, count, variant = 'danger' }: CounterProps) =>
  count ? (
    <StyledCounter data-e2e={dataE2e} variant={variant}>
      {count}
    </StyledCounter>
  ) : null

export default memo(Counter)
