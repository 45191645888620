import type { ReactNode, SyntheticEvent } from 'react'
import React, { memo, useCallback } from 'react'

import type { IconName } from '../../core'
import { Icon } from '../../core'
import Message from '../Input/Message/Message'
import type { ValueType } from '../RadioGroup/context/RadioGroupContext'
import { useRadioGroupContext } from '../RadioGroup/context/RadioGroupContext'
import { getInputVariant } from '../form.utils'
import type { InputProps } from '../interfaces'
import { RadioContainer, RadioInput, RadioLabel } from './Radio.styles'

export interface RadioProps extends InputProps<ValueType> {
  content?: ReactNode
  icon?: IconName
}

const Radio = ({
  'data-e2e': dataE2e,
  className,
  content,
  disabled: radioDisabled,
  errorMessage,
  hasError,
  hasWarning,
  icon,
  value,
  warningMessage,
}: RadioProps) => {
  const {
    allowDeselection,
    disabled,
    format = 'default',
    hasError: groupHasError,
    hasWarning: groupHasWarning,
    isChecked,
    name,
    onChange,
  } = useRadioGroupContext()
  const prefix = `${name}-`
  const radioId = `${prefix}${value}`

  const variant = getInputVariant({
    errorMessage,
    hasError: hasError || groupHasError,
    hasWarning: hasWarning || groupHasWarning,
    warningMessage,
  })

  const handleClick = useCallback(
    (event: SyntheticEvent) => {
      if (
        !!value &&
        value.toString() === event.currentTarget.id.substring(prefix.length) &&
        isChecked(value) &&
        allowDeselection
      ) {
        onChange({ name, value })
      }
    },
    [onChange, value, name, isChecked, allowDeselection]
  )

  const handleChange = useCallback(() => {
    onChange({ name, value: value ?? '' })
  }, [onChange, name, value])

  return (
    <RadioContainer
      className={className}
      disabled={!!disabled || !!radioDisabled}
      format={format}
      variant={variant}
    >
      <RadioInput
        checked={isChecked(value)}
        data-e2e={dataE2e}
        data-radio-selected={isChecked(value)}
        disabled={disabled || radioDisabled}
        id={radioId}
        name={name}
        onChange={handleChange}
        onClick={handleClick}
      />
      <RadioLabel htmlFor={radioId} format={format} variant={variant}>
        {isChecked(value) && format === 'toggled' && <Icon name="check" />}

        <span>
          {icon && <Icon faStyle="fas" name={icon} />}
          {content}
        </span>
      </RadioLabel>

      {!!errorMessage && (
        <Message data-e2e="radio-error-message" type="error" value={errorMessage} />
      )}
      {!!warningMessage && !errorMessage && (
        <Message data-e2e="radio-warning-message" type="warning" value={warningMessage} />
      )}
    </RadioContainer>
  )
}

export default memo(Radio)
