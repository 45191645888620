import styled from 'styled-components'

import type { InlineProps } from './Inline'

export const StyledInline = styled.div<InlineProps>`
  display: ${props => (props.inline ? 'inline-flex' : 'flex')};
  align-items: ${props => props.alignment};
  flex-wrap: ${props => (props.noWrap ? 'nowrap' : 'wrap')};
  white-space: ${props => (props.noWrap ? 'nowrap' : 'normal')};
  line-height: ${props => props.theme.fontSizes.medium};
  width: ${props => props.fluid && '100%'};
  gap: ${props => (props.spacing ? props.theme.spacings[props.spacing] : 0)};
`
