import React from 'react'
import { Provider } from 'react-redux'
import { Store } from 'redux'

import { BreakpointsProvider, ThemeProvider, procsea } from '@procsea/design-system'

import ConfiguredDataTableProvider from './ConfiguredDataTableProvider'
import AuthProvider from './authContext'
import { UserProvider } from './userContext'

interface AppProvidersProps {
  children: React.ReactNode
  store: Store
}

const AppProviders = ({ children, store }: AppProvidersProps) => (
  <ThemeProvider theme={procsea}>
    <ConfiguredDataTableProvider>
      <AuthProvider>
        <UserProvider>
          <BreakpointsProvider>
            <Provider store={store}>{children}</Provider>
          </BreakpointsProvider>
        </UserProvider>
      </AuthProvider>
    </ConfiguredDataTableProvider>
  </ThemeProvider>
)

export default AppProviders
