import React, { type ReactNode, forwardRef } from 'react'

import type { Size } from '../../../themes/types'
import { StyledBox } from './Box.styles'

export interface BoxProps {
  'data-e2e'?: string
  children?: ReactNode
  className?: string
  inset?: Size
}

const Box = forwardRef<HTMLDivElement, BoxProps>(({ children, ...props }, ref) => (
  <StyledBox {...props} ref={ref}>
    {children}
  </StyledBox>
))

export default Box
