import React from 'react'

import { withTooltip } from '../../../utils'
import Icon from '../../core/Icon/Icon'

export interface CommentIconProps {
  'data-e2e'?: string
  hasDot?: boolean
  hasPlus?: boolean
}

const CommentIcon = ({ 'data-e2e': dataE2e, hasDot, hasPlus }: CommentIconProps) => (
  <Icon
    data-e2e={hasPlus ? 'no-comment-icon' : dataE2e}
    hasDot={hasDot}
    hasPointer
    name={hasPlus ? 'comment-plus' : 'comment-lines'}
    size="lg"
    variant="primary"
  />
)

export default withTooltip(CommentIcon)
