import type { ReactNode } from 'react'
import React, { memo } from 'react'

import type { CustomTooltipProps } from '../Tooltip/Tooltip'
import { StyledContextMenu } from './ContextMenu.styles'

export interface ContextMenuProps {
  'data-e2e'?: string
  children?: ReactNode
  className?: string
  disabled?: boolean
  fallbackPlacements?: CustomTooltipProps['fallbackPlacements']
  hideArrow?: boolean
  onVisibilityChange?: CustomTooltipProps['onVisibilityChange']
  open?: boolean
  placement?: CustomTooltipProps['placement']
  reference: React.ReactNode
  strategy?: CustomTooltipProps['strategy']
  trigger?: CustomTooltipProps['trigger']
}

const ContextMenu = ({
  'data-e2e': dataE2e,
  children,
  className,
  disabled,
  fallbackPlacements,
  hideArrow = true,
  onVisibilityChange,
  open,
  placement: customPlacement = 'auto',
  reference,
  strategy = 'absolute',
  trigger = 'click',
}: ContextMenuProps) => (
  <StyledContextMenu
    className={className}
    data-e2e={dataE2e}
    fallbackPlacements={fallbackPlacements}
    hideArrow={hideArrow}
    onVisibilityChange={onVisibilityChange}
    open={open}
    placement={customPlacement}
    strategy={strategy}
    tooltip={children}
    trigger={disabled ? null : trigger}
    variant="white"
  >
    {reference}
  </StyledContextMenu>
)

export default memo(ContextMenu)
