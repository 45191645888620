import React from 'react'

import type { Style } from '../../../themes/types'
import { StyledGlobalStyle } from './GlobalStyle.styles'

export interface GlobalStyleProps {
  styledTheme?: Style
}

const GlobalStyle = ({ styledTheme = 'light' }: GlobalStyleProps) => (
  <StyledGlobalStyle styledTheme={styledTheme} />
)

export default GlobalStyle
