import styled from 'styled-components'

import type { Variant } from '../../../themes'

export const StockLabel = styled.span<{ variant: Variant }>`
  display: inline-flex;
  align-items: center;
  margin-right: ${props => props.theme.spacings.xsmall};
  font-size: ${props => props.theme.fontSizes.small};
  color: ${props => props.theme.colors[props.variant].base};
`

export const StyledStock = styled.div<{ variant: Variant }>`
  display: inline-flex;
  align-items: center;

  &:before {
    content: '';
    margin-right: ${props => props.theme.spacings.xsmall};
    background-color: ${props => props.theme.colors[props.variant].base};
    width: 7px;
    height: 7px;
    border-radius: 50%;
  }
`
