import type { ReactNode } from 'react'
import React from 'react'

import { Divider } from '../../../core'
import Box from '../../../layout/Box/Box'
import { StyledSlidingPanelFooter } from './SlidingPanelFooter.styles'

export interface SlidingPanelFooterProps {
  'data-e2e'?: string
  children?: ReactNode
}
const SlidingPanelFooter = ({ 'data-e2e': dataE2e, children }: SlidingPanelFooterProps) => (
  <StyledSlidingPanelFooter>
    <Divider />
    <Box data-e2e={`${dataE2e}--footer`} inset="medium">
      {children}
    </Box>
  </StyledSlidingPanelFooter>
)

export default SlidingPanelFooter
