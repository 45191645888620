import React, { createContext, useContext, useMemo } from 'react'

interface DataTableContextValue {
  actionBarFeedbackButtonLabel: string
  actionMenuButtonLabel: string
  actionMenuTitle: string
  configMenuOpenButtonLabel: string
  configMenuTitle: string
  configMenuPinnedColumnSubTitle: string
  configMenuRemainingColumnSubTitle: string
  configMenuResetButtonLabel: string
  countFetchingText: string
  getRowCountText: (rowCount: number) => string
  configMenuPinColumnButtonTooltip: string
}

const DataTableContext = createContext<DataTableContextValue | undefined>(undefined)

type DataTableProviderProps = DataTableContextValue & {
  children: React.ReactNode
}

export const DataTableProvider = ({
  children,
  actionBarFeedbackButtonLabel,
  actionMenuButtonLabel,
  actionMenuTitle,
  configMenuOpenButtonLabel,
  configMenuResetButtonLabel,
  configMenuTitle,
  configMenuPinnedColumnSubTitle,
  configMenuRemainingColumnSubTitle,
  configMenuPinColumnButtonTooltip,
  countFetchingText,
  getRowCountText,
}: DataTableProviderProps) => {
  const dataTableConfig = useMemo(
    () => ({
      actionBarFeedbackButtonLabel,
      actionMenuButtonLabel,
      actionMenuTitle,
      configMenuOpenButtonLabel,
      configMenuResetButtonLabel,
      configMenuTitle,
      configMenuPinnedColumnSubTitle,
      configMenuRemainingColumnSubTitle,
      configMenuPinColumnButtonTooltip,
      countFetchingText,
      getRowCountText,
    }),
    [
      actionBarFeedbackButtonLabel,
      actionMenuButtonLabel,
      actionMenuTitle,
      configMenuOpenButtonLabel,
      configMenuResetButtonLabel,
      configMenuTitle,
      configMenuPinnedColumnSubTitle,
      configMenuRemainingColumnSubTitle,
      configMenuPinColumnButtonTooltip,
      countFetchingText,
      getRowCountText,
    ]
  )

  return <DataTableContext.Provider value={dataTableConfig}>{children}</DataTableContext.Provider>
}

export const useDataTableContext = (): DataTableContextValue => {
  const context = useContext(DataTableContext)

  if (context === undefined) {
    throw new Error('useDataTableContext must be used within a DataTableProvider')
  }

  return context
}
