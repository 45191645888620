import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import isNil from 'lodash/isNil'
import React, { useEffect, useState } from 'react'
import {
  type CSSObjectWithLabel,
  type FormatOptionLabelMeta,
  type OnChangeValue,
  type Options,
  createFilter,
} from 'react-select'

import { ZIndex } from '../../../constants/ZIndex'
import Message from '../Input/Message/Message'
import Label from '../Label/Label'
import { getInputVariant } from '../form.utils'
import {
  MainContainer,
  StyledInlineLabel,
  StyledSelect,
  StyledSelectContainer,
} from './Select.styles'
import type { MyOptionType, SelectProps } from './Select.types'
import {
  DropdownIndicator,
  Menu,
  MenuList,
  MultiValue,
  Option,
  OptionWithEdit,
} from './SelectComponents'

const components = {
  DropdownIndicator,
  Menu,
  MenuList,
  MultiValue,
  Option,
}

export const applyDefaultSelectFilter: any = createFilter()

export const findOptionFromValue = <Value, IsMulti extends boolean>(
  value: OnChangeValue<Value, IsMulti> | undefined,
  options: Options<MyOptionType<Value>>,
  isMulti: IsMulti
) =>
  isMulti
    ? options.filter(option =>
        (value as OnChangeValue<Value, true> | undefined)?.includes(option.value)
      )
    : options.find(option => isEqual(option.value, value))

export const isNotEmpty = (value: any) =>
  !!(Array.isArray(value) ? value.length !== 0 : value && !isEmpty(value))

const Select = <Value, IsMulti extends boolean>({
  'aria-label': ariaLabel,
  'data-e2e': dataE2e = 'select',
  className,
  clearable = false,
  createLabel,
  defaultValue,
  disabled = false,
  editLabel,
  errorMessage,
  filterOption,
  fluid = false,
  formatOptionLabel,
  formatValueLabel,
  hasError,
  hasWarning,
  helpText,
  inline = false,
  isLoading = false,
  label,
  maxVisibleSelectedOptions = 2,
  menuIsOpen,
  menuPortalTarget,
  minWidth = '15rem',
  multiple = false as IsMulti,
  name,
  onChange,
  onCreate,
  onEdit,
  options,
  placeholder = '',
  required = false,
  rightPosition = false,
  searchable = true,
  styles,
  value,
  warningMessage,
  width,
  wrapOptions = false,
}: SelectProps<Value, IsMulti>) => {
  const [hasValue, setHasValue] = useState(!!value)

  const variant = getInputVariant({ errorMessage, hasError, hasWarning, warningMessage })

  const innerValue = isNil(value)
    ? value
    : findOptionFromValue(value as OnChangeValue<Value, IsMulti>, options, multiple)
  const innerDefaultValue = findOptionFromValue(defaultValue, options, multiple)
  const isEditable = typeof onEdit === 'function'

  useEffect(() => {
    setHasValue(isNotEmpty(innerValue) || isNotEmpty(innerDefaultValue))
  }, [innerValue, innerDefaultValue])

  const handleChange = (selected: OnChangeValue<MyOptionType<Value>, IsMulti>) => {
    setHasValue(isNotEmpty(selected))

    if (multiple) {
      const newValue: OnChangeValue<Value, true> = (
        selected as OnChangeValue<MyOptionType<Value>, true>
      ).map(option => option.value)
      onChange?.({ name, value: newValue as OnChangeValue<Value, IsMulti> })
    } else {
      const newValue: OnChangeValue<Value, false> | undefined =
        selected === null ? null : (selected as MyOptionType<Value>).value
      onChange?.({ name, value: newValue as OnChangeValue<Value, IsMulti> })
    }
  }

  const handleOptionFormatting: (
    option: MyOptionType<Value>,
    labelMeta: FormatOptionLabelMeta<MyOptionType<Value>>
  ) => React.ReactNode = (option, { context }) => {
    if (context === 'menu' && formatOptionLabel) {
      return isEditable ? (
        <OptionWithEdit
          editLabel={editLabel}
          label={option.label}
          onEdit={onEdit as () => void}
          value={option.value}
        >
          {formatOptionLabel(option)}
        </OptionWithEdit>
      ) : (
        formatOptionLabel(option)
      )
    }
    if (context === 'value' && formatValueLabel) {
      return formatValueLabel(option)
    }

    return isEditable && context === 'menu' ? (
      <OptionWithEdit
        editLabel={editLabel}
        label={option.label}
        onEdit={onEdit as () => void}
        value={option.value}
      >
        {option.label}
      </OptionWithEdit>
    ) : (
      option.label
    )
  }

  return (
    <MainContainer
      aria-label={ariaLabel}
      className={className}
      data-e2e={dataE2e}
      disabled={disabled}
      fluid={fluid}
    >
      {!!label &&
        (inline ? (
          <StyledInlineLabel htmlFor={name}>{label}</StyledInlineLabel>
        ) : (
          <Label htmlFor={name} text={label} helpText={helpText} required={required} />
        ))}
      <StyledSelectContainer
        disabled={disabled}
        fluid={fluid}
        inline={inline}
        minWidth={minWidth}
        width={width}
      >
        <StyledSelect
          classNamePrefix="Select"
          closeMenuOnSelect={!multiple}
          components={components}
          createLabel={createLabel}
          defaultValue={innerDefaultValue}
          disabled={disabled || isLoading}
          filled={hasValue}
          filterOption={filterOption}
          formatOptionLabel={handleOptionFormatting as () => React.ReactNode}
          hideSelectedOptions={false}
          inline={inline}
          inputId={name}
          isClearable={clearable}
          isDisabled={disabled || isLoading}
          isMulti={multiple}
          isSearchable={searchable}
          loading={isLoading}
          maxVisibleSelectedOptions={maxVisibleSelectedOptions}
          menuIsOpen={menuIsOpen}
          menuPlacement="auto"
          menuPortalTarget={menuPortalTarget}
          name={name}
          onChange={handleChange as () => void}
          onCreate={onCreate}
          options={options}
          placeholder={placeholder}
          rightPosition={rightPosition}
          styles={{
            control: base => base,
            menuPortal: base => ({ ...base, zIndex: ZIndex.SELECT_MENU }) as CSSObjectWithLabel,
            ...styles,
          }}
          value={innerValue}
          variant={variant}
          wrapOptions={wrapOptions}
        />
      </StyledSelectContainer>

      {!!errorMessage && (
        <Message data-e2e="select-error-message" type="error" value={errorMessage} />
      )}
      {!!warningMessage && !errorMessage && (
        <Message data-e2e="select-warning-message" type="warning" value={warningMessage} />
      )}
    </MainContainer>
  )
}

export default Select
