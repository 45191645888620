import type { ReactNode } from 'react'
import React, { useEffect, useState } from 'react'

import type { Width } from '../../../themes'
import Text from '../Text/Text'
import { ModalContent, ModalFooter, ModalHeader, StyledModal } from './Modal.styles'

export interface ModalProps {
  'data-e2e'?: string
  afterClose?: () => void
  afterOpen?: () => void
  beforeClose?: () => void
  beforeOpen?: () => void
  children?: ReactNode
  className?: string
  disableClose?: boolean
  fluid?: boolean
  footer?: React.ReactNode
  header?: React.ReactNode
  onBackgroundClick?: () => void
  open?: boolean
  width?: Width
}

const Modal = ({
  'data-e2e': dataE2e,
  afterClose,
  afterOpen,
  beforeClose,
  beforeOpen,
  children,
  className,
  disableClose = false,
  fluid = false,
  footer,
  header,
  onBackgroundClick,
  open = false,
  width,
}: ModalProps) => {
  const [isOpen, setIsOpen] = useState(open)

  useEffect(() => setIsOpen(open), [open])

  const toggleModal = () => {
    if (!disableClose) setIsOpen(!isOpen)
  }

  const handleBackgroundClick = () => {
    if (!disableClose) {
      onBackgroundClick?.()
      toggleModal()
    }
  }

  return (
    <StyledModal
      afterClose={afterClose}
      afterOpen={afterOpen}
      beforeClose={beforeClose}
      beforeOpen={beforeOpen}
      className={className}
      data-e2e={dataE2e}
      fluid={fluid}
      isOpen={isOpen}
      onBackgroundClick={handleBackgroundClick}
      onEscapeKeydown={toggleModal}
      role="dialog"
      width={width}
    >
      {header && (
        <ModalHeader data-e2e={`${dataE2e}--header`}>
          {typeof header === 'string' ? (
            <Text heading variant="secondary" size="xxlarge">
              {header}
            </Text>
          ) : (
            header
          )}
        </ModalHeader>
      )}

      <ModalContent data-e2e={`${dataE2e}--content`}>{children}</ModalContent>

      {footer && <ModalFooter data-e2e={`${dataE2e}--footer`}>{footer}</ModalFooter>}
    </StyledModal>
  )
}

export default Modal
