import styled from 'styled-components'

import type { Size, Variant } from '../../../themes/types'

export const StyledAlertIcon = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: ${props => props.theme.spacings.medium};
`

export const StyledAlert = styled.div<{ spacing: Size; variant: Variant; fluid: boolean }>`
  display: flex;
  padding: ${props => props.theme.spacings[props.spacing]};
  border: 1px solid;
  border-radius: ${props => props.theme.dimensions.borderRadius};
  background-color: ${props => props.theme.colors[props.variant].lightest};
  border-color: ${props => props.theme.colors[props.variant].base};
  width: ${props => (props.fluid ? '100%' : 'auto')};

  & > ${StyledAlertIcon} {
    color: ${props => props.theme.colors[props.variant].base};
  }
`

export const AlertContent = styled('p')`
  margin: 0;
  color: ${props => props.theme.colors.secondary.base};
`
