import { animated, useTransition } from '@react-spring/web'
import type { PropsWithChildren } from 'react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'

import { procsea } from '../../../../themes'
import BurgerMenuTopBar from '../BurgerMenuTopBar/BurgerMenuTopBar'
import type { MenuContentProps } from '../MenuContent/MenuContent'
import MenuContent from '../MenuContent/MenuContent'
import { StyledSlider, StyledSliderBackground } from './BurgerMenu.styles'

const AnimatedSlider = animated(StyledSlider)
const AnimatedSliderBackground = animated(StyledSliderBackground)

export type BurgerMenuProps = Omit<MenuContentProps, 'onClose'> & { title?: string }

const BurgerMenu = ({
  'data-e2e': dataE2e,
  title,
  ...props
}: PropsWithChildren<BurgerMenuProps>) => {
  const prevBodyOverflowStyle = useRef<string | null>(null)

  const [isOpen, setIsOpen] = useState(false)

  const handleClose = () => setIsOpen(false)

  const handleOpen = () => setIsOpen(true)

  const transitions = useTransition(isOpen, {
    from: {
      backgroundColor: 'rgba(0,0,0,0)',
      boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.05)',
      marginLeft: `-${procsea.menu.dimensions.desktop.width}`,
    },
    enter: {
      backgroundColor: 'rgba(0,0,0,0.5)',
      boxShadow: '0px 0px 30px 0px rgba(0,0,0,0.20)',
      marginLeft: '0px',
    },
    leave: {
      backgroundColor: 'rgba(0,0,0,0)',
      boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.05)',
      marginLeft: `-${procsea.menu.dimensions.desktop.width}`,
    },
  })

  const stopEventPropagation = useCallback((e: any) => {
    e.stopPropagation()

    let { target } = e

    while (target) {
      if (target.getAttribute('data-menu') === 'menu-entry') {
        handleClose()
        break
      }
      target = target.parentElement
    }
  }, [])

  useEffect(() => {
    if (isOpen) {
      prevBodyOverflowStyle.current = document.body.style.overflow
      document.body.style.overflow = 'hidden'
    }

    return () => {
      document.body.style.overflow = prevBodyOverflowStyle.current || ''
    }
  }, [isOpen])

  return (
    <>
      <BurgerMenuTopBar data-e2e={dataE2e} onClick={handleOpen} title={title} />
      {ReactDOM.createPortal(
        transitions(
          ({ backgroundColor, boxShadow, marginLeft }, item) =>
            item && (
              <AnimatedSliderBackground
                data-e2e={`${dataE2e}--overlay`}
                onClick={handleClose}
                style={{ backgroundColor }}
              >
                <AnimatedSlider onClick={stopEventPropagation} style={{ boxShadow, marginLeft }}>
                  <MenuContent data-e2e={dataE2e} onClose={handleClose} {...props} />
                </AnimatedSlider>
              </AnimatedSliderBackground>
            )
        ),
        document.body
      )}
    </>
  )
}

export default BurgerMenu
