import { type Column } from '@tanstack/react-table'
import { type CSSProperties } from 'react'

export const getCommonPinningStyles = <TData>(column: Column<TData>): CSSProperties => {
  const isPinned = column.getIsPinned()
  const isLastLeftPinnedColumn = isPinned === 'left' && column.getIsLastColumn('left')

  return {
    borderRight: isLastLeftPinnedColumn ? '1px solid #979797' : undefined,
    left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
    position: isPinned ? 'sticky' : 'relative',
    zIndex: isPinned ? 1 : 0,
  }
}
