import { type Row, type Table, flexRender } from '@tanstack/react-table'
import { type Virtualizer } from '@tanstack/react-virtual'
import React, { useCallback } from 'react'

import { getCommonPinningStyles } from '../../utils/getCommonPinningStyles'
import { TableCell, TableRow } from '../DataTableCore.styles'

export interface DataTableBodyProps<TData> {
  table: Table<TData>
  rowVirtualizer: Virtualizer<HTMLDivElement, Element>
}

export const DataTableBody = <TData,>({ rowVirtualizer, table }: DataTableBodyProps<TData>) => {
  const tableBodyRef = React.useRef<HTMLTableSectionElement>(null)
  const { rows } = table.getRowModel()

  const measureTableRow = useCallback(
    (node: HTMLTableRowElement | null) => rowVirtualizer.measureElement(node),
    [rowVirtualizer]
  )

  return (
    <tbody
      ref={tableBodyRef}
      style={{
        display: 'grid',
        height: `${rowVirtualizer.getTotalSize()}px`,
        position: 'relative',
      }}
    >
      {rowVirtualizer.getVirtualItems().map(virtualRow => {
        const row = rows[virtualRow.index] as Row<TData>
        return (
          <TableRow
            data-e2e="data-table-row"
            data-index={virtualRow.index}
            data-row-disabled={(row.original as TData & { disabled?: boolean }).disabled ?? false}
            key={row.id}
            ref={measureTableRow}
            style={{
              display: 'flex',
              position: 'absolute',
              transform: `translateY(${virtualRow.start - rowVirtualizer.options.scrollMargin}px)`,
            }}
          >
            {row.getVisibleCells().map(cell => {
              const { column } = cell

              return (
                <TableCell
                  data-e2e="data-table-cell"
                  id={cell.id}
                  key={cell.id}
                  columnId={cell.column.id}
                  style={{
                    ...getCommonPinningStyles(column),
                    justifyContent: cell.column.columnDef.meta?.align ?? 'start',
                  }}
                >
                  <span>{flexRender(cell.column.columnDef.cell, cell.getContext())}</span>
                </TableCell>
              )
            })}

            <TableCell columnId="last-column" />
          </TableRow>
        )
      })}
    </tbody>
  )
}

// Special memoized wrapper for the table body that will be used during column resizing
export const MemoizedDataTableBody = React.memo(
  DataTableBody,
  (prev, next) => prev.table.options.data === next.table.options.data
) as typeof DataTableBody
