import React, { useCallback } from 'react'
import styled from 'styled-components'

import { type QualityType, qualities } from '../../../themes'
import { Checkbox, CheckboxGroup, type FormOnChange, Radio, RadioGroup } from '../../form'
import { type ValueType } from '../../form/RadioGroup/context/RadioGroupContext'
import Quality from '../Quality/Quality'

export interface QualitySelectorProps<isMulti extends boolean> {
  'data-e2e'?: string
  errorMessage?: string
  label?: string
  name?: string
  onChange?: isMulti extends true
    ? FormOnChange<QualityType[] | null>
    : FormOnChange<QualityType | null>
  value?: isMulti extends true ? QualityType[] : QualityType
  required?: boolean
  multiple?: isMulti
  warningMessage?: string
}

const StyledCheckbox = styled(Checkbox)`
  && label {
    padding: ${props => props.theme.spacings.small};
  }
`

const QualitySelector = <isMulti extends boolean>({
  'data-e2e': dataE2e = 'quality-radio-group',
  errorMessage,
  label,
  name = 'quality',
  onChange,
  required = false,
  value,
  warningMessage,
  multiple,
}: QualitySelectorProps<isMulti>) => {
  const handleChange: FormOnChange<isMulti extends true ? ValueType[] | null : ValueType | null> =
    useCallback(
      ({ value: newValue }) =>
        onChange?.({
          value: newValue as QualityType[] & QualityType,
        }),
      [onChange]
    )

  return multiple ? (
    <CheckboxGroup
      data-e2e={dataE2e}
      errorMessage={errorMessage}
      format="toggled"
      label={label}
      name={name}
      noWrap
      onChange={handleChange as FormOnChange<ValueType[] | null>}
      required={required}
      value={value as QualityType[]}
      warningMessage={warningMessage}
    >
      {qualities.map(quality => (
        <StyledCheckbox
          data-e2e="label-checkbox"
          content={<Quality quality={quality} fixedSize />}
          key={quality}
          name={quality}
          value={value?.includes(quality)}
        />
      ))}
    </CheckboxGroup>
  ) : (
    <RadioGroup
      allowDeselection
      data-e2e={dataE2e}
      errorMessage={errorMessage}
      format="toggled"
      label={label}
      name={name}
      noWrap
      onChange={handleChange as FormOnChange<ValueType | null>}
      required={required}
      value={value as QualityType}
      warningMessage={warningMessage}
    >
      {qualities.map(quality => (
        <Radio content={<Quality quality={quality} fixedSize />} key={quality} value={quality} />
      ))}
    </RadioGroup>
  )
}

export default QualitySelector
