import { Arrow, Close, Content } from '@radix-ui/react-popover'
import { invert } from 'polished'
import styled, { keyframes } from 'styled-components'

import type { AdvancedColors, ColorShade, Variant } from '../../../themes'
import { getForegroundColor } from '../../../themes/helpers'

const getBackgroundColor = (colors: AdvancedColors, variant: Variant, shade: ColorShade) => {
  if (variant === 'black') return '#262626'
  if (variant === 'white') return variant

  return colors[variant][shade]
}

export const slideUpAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

export const slideRightAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`

export const slideDownAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

export const slideLeftAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`

export const StyledContent = styled(Content)<{ $variant: Variant }>`
  display: flex;
  flex-direction: column;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  z-index: 10001;
  margin: 0;
  padding: ${props => props.theme.spacingsInsetSquish.small};
  background-color: ${props => getBackgroundColor(props.theme.colors, props.$variant, 'base')};
  border-color: ${props => getBackgroundColor(props.theme.colors, props.$variant, 'dark')};
  border-radius: ${props => props.theme.dimensions.borderRadius};
  color: ${props =>
    props.$variant === 'black' || props.$variant === 'white'
      ? invert(props.$variant)
      : getForegroundColor(props.$variant, props.theme)};

  &[data-state='open'][data-side='top'] {
    animation-name: ${slideDownAndFade};
  }
  &[data-state='open'][data-side='right'] {
    animation-name: ${slideLeftAndFade};
  }
  &[data-state='open'][data-side='bottom'] {
    animation-name: ${slideUpAndFade};
  }
  &[data-state='open'][data-side='left'] {
    animation-name: ${slideRightAndFade};
  }
`

export const StyledArrow = styled(Arrow)<{ $variant: Variant }>`
  fill: ${props => getBackgroundColor(props.theme.colors, props.$variant, 'base')};
`

export const StyledClose = styled(Close)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.neutral.base};
  position: absolute;
  top: ${props => props.theme.spacings.medium};
  right: ${props => props.theme.spacings.medium};
`
