import React from 'react'
import type { Row as TableRow } from 'react-table'

import { CardRow, Cell, Row } from '../Table.styles'

interface BodyProps {
  onClick?: (id: number) => void
  prepareRow: (row: TableRow) => void
  rows: TableRow[]
}

const Body = ({ onClick, prepareRow, rows }: BodyProps) => (
  <>
    {rows.map((row: any, rowIndex) => {
      prepareRow(row)
      const { key, ...restRowProps } = row.getRowProps()

      return (
        <CardRow
          data-row-disabled={(row.state.opacity ?? 1) !== 1}
          data-row-selected={!!row.state.isEdited}
          isEdited={!!row.state.isEdited}
          key={rowIndex}
          onClick={() => (onClick ? onClick(row.original.id) : null)}
          opacity={row.state.opacity}
          spacing="small"
          variant={row.state.variant}
        >
          <Row key={key} {...restRowProps}>
            {row.cells.map((cell: any, cellIndex: number) => (
              <Cell
                {...cell.getCellProps()}
                bold={cell.column.bold}
                align={cell.column.align || 'left'}
                key={cellIndex}
              >
                {cell.render('Cell')}
              </Cell>
            ))}
          </Row>
        </CardRow>
      )
    })}
  </>
)

export default Body
