import { createGlobalStyle } from 'styled-components'

import type { Style } from '../../../themes/types'

export const StyledGlobalStyle = createGlobalStyle<{ styledTheme: Style }>`
  html, body {
    font-family: ${props => props.theme.fontFamilies};
    font-size: ${props => props.theme.styles[props.styledTheme].fontSize};
    background: ${props => props.theme.styles[props.styledTheme].background};
    color: ${props => props.theme.styles[props.styledTheme].color};
  }
  a {
    cursor: pointer;
    color: ${props => props.theme.colors.primary.base};

    &:hover {
      color: ${props => props.theme.colors.primary.darker};
    }
  }
`
