import React, { memo } from 'react'
import { Link } from 'react-router-dom'

import { Button } from '@procsea/design-system'

import { useLocale } from 'src/hooks'

import './NotFound.css'

const NotFound = () => {
  const locale = useLocale()

  return (
    <div className="not-found-container" data-e2e="not-found-page">
      <h2>{gettext('404 - Not Found')}</h2>
      <h3>{gettext('Ooops!')}</h3>
      <p>{gettext("That page doesn't exist or is unavailable")}</p>

      <Link to={`/${locale}`}>
        <Button variant="primary">{gettext('Go Back to Home')}</Button>
      </Link>
    </div>
  )
}

export default memo(NotFound)
