import styled from 'styled-components'

import type { Variant } from '../../../themes'

export const StyledCounter = styled.span<{ variant: Variant }>`
  background-color: ${props => props.theme.colors[props.variant].darker};
  border-radius: 10px;
  color: ${props => props.theme.colors.white.base};
  display: inline-block;
  font-size: ${props => props.theme.fontSizes.small};
  line-height: ${props => props.theme.fontSizes.small};
  padding: ${props => props.theme.spacings.xxsmall} ${props => props.theme.spacings.small};
`
