import styled from 'styled-components'

import type { DividerProps } from './Divider'

export const StyledDivider = styled.div<DividerProps>`
  ${props => props.bottomSpacing && `padding-bottom: ${props.theme.spacings[props.bottomSpacing]};`}

  ${props =>
    props.sideSpacing &&
    `
    padding-left: ${props.theme.spacings[props.sideSpacing]};
    padding-right: ${props.theme.spacings[props.sideSpacing]};
  `}

  ${props => props.topSpacing && `padding-top: ${props.theme.spacings[props.topSpacing]};`}

  & > div {
    background-color: ${props => props.theme.colors.neutral.light};
    height: ${props => (props.verticalHeight ? props.theme.spacings[props.verticalHeight] : '1px')};
    width: ${props => (props.verticalHeight ? '1px' : '100%')};
  }
`
