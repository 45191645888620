import type { ReactNode } from 'react'
import React from 'react'

import { Text } from '..'
import type { FontSize, Variant } from '../../../themes/types'
import Inline from '../../layout/Inline/Inline'
import type { IconName, IconProps } from '../Icon/Icon'
import Icon from '../Icon/Icon'

export interface IconWrapperProps {
  children?: ReactNode
  className?: string
  icon: IconName
  outline?: boolean
  noWrap?: boolean
  size?: IconProps['size']
  textSize?: FontSize
  variant?: Variant
  'data-e2e'?: string
}

const IconWrapper = ({
  children,
  className,
  icon,
  outline = false,
  noWrap = false,
  size = '1x',
  textSize = 'medium',
  variant,
  'data-e2e': dataE2e,
}: IconWrapperProps) => (
  <Inline alignment="center" spacing="xsmall" className={className} noWrap>
    <Icon name={icon} faStyle={outline ? 'far' : 'fas'} size={size} variant={variant} />

    <Text data-e2e={dataE2e} as="span" size={textSize} wrap={!noWrap} noWrap={noWrap}>
      {children}
    </Text>
  </Inline>
)

export default IconWrapper
