import styled from 'styled-components'

import { DEFAULT_ROW_HEIGHT } from '../../DataTable.constants'

export const StyledDataLoaderContainer = styled.div<{ width: number }>`
  background-color: ${props => props.theme.colors.white.base};
  padding: ${props => props.theme.spacings.small};
  width: ${props => props.width}px;
  min-width: 100%;
  border-bottom: 2px solid ${props => props.theme.colors.neutral.lighter};
  min-height: ${DEFAULT_ROW_HEIGHT}px;
  align-content: center;
`

export const StyledDataLoaderBackground = styled.div`
  overflow: hidden;
  position: relative;
  background-color: ${props => props.theme.colors.neutral.lighter};
  border-radius: 8px;
  max-width: 100%;
  min-height: 12px;
  width: 100%;
`

export const StyledDataLoaderBar = styled.div`
  display: block;
  position: absolute;
  animation: moving-loader 1.5s linear infinite;
  background-color: ${props => props.theme.colors.neutral.light};
  height: 12px;
  width: 10%;

  @keyframes moving-loader {
    0% {
      left: 0%;
      width: 0%;
    }

    50% {
      width: 30%;
    }

    70% {
      width: 70%;
    }

    80% {
      left: 60%;
    }

    100% {
      left: 100%;
      width: 100%;
    }
  }
`
