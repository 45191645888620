import { shade } from 'polished'
import styled from 'styled-components'

import type { Variant } from '../../../../themes'

export const StyledContextMenuItem = styled.div<{ disabled?: boolean; variant: Variant }>`
  color: ${props => props.theme.colors[props.variant].base};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => (props.disabled ? props.theme.opacities.disabled : null)};
  padding: ${props => props.theme.spacingsInset.medium};

  &:first-child {
    border-top-left-radius: ${props => props.theme.dimensions.borderRadius};
    border-top-right-radius: ${props => props.theme.dimensions.borderRadius};
  }
  &:last-child {
    border-bottom-left-radius: ${props => props.theme.dimensions.borderRadius};
    border-bottom-right-radius: ${props => props.theme.dimensions.borderRadius};
  }

  &:hover,
  & a:hover {
    color: ${props => props.theme.colors[props.variant].base};
    background-color: ${props => (props.disabled ? 'unset' : props.theme.colors.neutral.lighter)};
  }

  &:active,
  & a:active {
    color: ${props => props.theme.colors[props.variant].base};
    background-color: ${props =>
      props.disabled ? 'unset' : shade(0.05, props.theme.colors.neutral.lighter)};
  }

  & a {
    color: ${props => props.theme.colors[props.variant].base};
  }
`

export const StyledContextMenuItemIcon = styled.div`
  width: 1rem;
  display: flex;
  justify-content: center;
  align-item: center;
`
