import { CollapsibleContent as Content, Collapsible as Root } from '@radix-ui/react-collapsible'
import styled, { keyframes } from 'styled-components'

const slideDown = keyframes`
  from { height: 0; }
  to { height: var(--radix-collapsible-content-height);	}
`

const slideUp = keyframes`
  from { height: var(--radix-collapsible-content-height); }
  to { height: 0; }
`

export const CollapsibleRoot = styled(Root)`
  &[data-fluid='true'] {
    width: 100%;
  }
`

export const CollapsibleContent = styled(Content)<{ duration: number }>`
  overflow: hidden;

  &[data-state='open'] {
    animation: ${slideDown} ${props => props.duration}ms ease-out;
  }

  &[data-state='closed'] {
    animation: ${slideUp} ${props => props.duration}ms ease-out;
  }
`
