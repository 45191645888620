import styled, { css } from 'styled-components'

import type { Variant } from '../../../../themes'
import { Icon } from '../../../core'
import { StyledFlex } from '../../../layout/Flex/Flex.styles'
import { menuEntryActiveIndicatorWidth } from '../Menu.constants'

export interface MenuEntryConfig {
  disableActiveState?: boolean
  disableHoverState?: boolean
}

export const StyledMenuEntryIcon = styled(Icon)`
  color: ${props =>
    props.variant
      ? props.theme.menu.colors[props.variant].base
      : props.theme.menu.colors.foreground.dark};
  cursor: pointer;
  width: 16px;
  &:hover {
    cursor: pointer;
  }
`

const menuEntryActiveState = css`
  background-color: ${props => props.theme.menu.colors.secondary.lightest};
  ${StyledMenuEntryIcon} {
    color: ${props => props.theme.menu.colors.primary.base};
  }
  color: ${props => props.theme.menu.colors.black.base};
  &::before {
    background-color: ${props => props.theme.menu.colors.primary.base};
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    border-radius: ${props => props.theme.dimensions.borderRadius} 0 0
      ${props => props.theme.dimensions.borderRadius};
    width: ${menuEntryActiveIndicatorWidth};
  }
`

export const StyledMenuEntry = styled.div<{
  config?: MenuEntryConfig
  isActive: boolean
  variant?: Variant
}>`
  align-items: center;
  border-radius: ${props => props.theme.dimensions.borderRadius};
  color: ${props =>
    props.variant
      ? props.theme.menu.colors[props.variant].base
      : props.theme.menu.colors.foreground.dark};
  cursor: pointer;
  display: flex;
  min-height: 32px;
  padding: ${props =>
    `0 ${props.theme.spacings.small} 0 calc(${props.theme.spacings.small} + ${menuEntryActiveIndicatorWidth})`};
  position: relative;
  user-select: none;
  width: 100%;

  ${props =>
    !props.config?.disableHoverState &&
    `&:hover {
    background-color: ${props.theme.menu.colors.secondary.lightest};
  }`}

  &:active {
    ${props => !props.config?.disableActiveState && menuEntryActiveState}
  }

  & a,
  a:hover,
  a:active {
    color: ${props =>
      props.variant
        ? props.theme.menu.colors[props.variant].base
        : props.theme.menu.colors.foreground.dark};
  }

  ${props => props.isActive && menuEntryActiveState};

  ${StyledFlex} > div:first-child, & > div:first-child {
    overflow: hidden;
  }
`
