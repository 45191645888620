import React, { type SyntheticEvent, memo, useCallback, useState } from 'react'

import { useEffectAfterMount } from '../../../hooks'
import Message from '../Input/Message/Message'
import Label from '../Label/Label'
import { getInputVariant } from '../form.utils'
import { type InputProps } from '../interfaces'
import { TextareaContainer, TextareaField } from './Textarea.styles'

export interface TextareaProps extends InputProps<string> {
  maxLength?: number
  minLength?: number
  rows?: number
}

const Textarea = ({
  'data-e2e': dataE2e = 'textarea',
  className,
  disabled = false,
  errorMessage,
  fluid,
  hasError,
  hasWarning,
  helpText,
  label,
  maxLength,
  minLength,
  name,
  onChange,
  placeholder,
  required = false,
  rows = 5,
  value = '',
  warningMessage,
}: TextareaProps) => {
  const [innerValue, setInnerValue] = useState(value)

  const variant = getInputVariant({ errorMessage, hasError, hasWarning, warningMessage })

  useEffectAfterMount(() => setInnerValue(value), [value])

  const handleChange = useCallback(
    (event: SyntheticEvent) => {
      const target = event.target as HTMLInputElement

      setInnerValue(target.value)
      onChange?.({ name, value: target.value })
    },
    [name, onChange]
  )

  return (
    <TextareaContainer className={className} fluid={fluid}>
      {!!label && <Label htmlFor={name} text={label} helpText={helpText} required={required} />}

      <TextareaField
        data-e2e={dataE2e}
        disabled={disabled}
        filled={!!innerValue}
        id={name}
        maxLength={maxLength}
        minLength={minLength}
        name={name}
        onChange={handleChange}
        placeholder={placeholder}
        rows={rows}
        value={innerValue}
        variant={variant}
      />

      {!!errorMessage && (
        <Message data-e2e="textarea-error-message" type="error" value={errorMessage} />
      )}
      {!!warningMessage && !errorMessage && (
        <Message data-e2e="textarea-warning-message" type="warning" value={warningMessage} />
      )}
    </TextareaContainer>
  )
}

export default memo(Textarea)
