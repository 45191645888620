import React from 'react'

import { type Variant } from '../../../themes'
import { IconWrapper, Text } from '../../core'
import { memoizeTableCell } from '../../core/DataTable/utils/memoizeTableCell'

export interface PackagingProps {
  'data-e2e'?: string
  quantity: number
  unit: string
  variant?: Variant
}

const Packaging = ({
  'data-e2e': dataE2e = 'packaging',
  quantity,
  unit,
  variant = 'secondary',
}: PackagingProps) => (
  <IconWrapper data-e2e={dataE2e} icon="box-taped" outline variant={variant}>
    <Text variant={variant} noWrap fontCase="lowercase">
      x {quantity} {unit}
    </Text>
  </IconWrapper>
)

export default memoizeTableCell(Packaging)
