import type { ReactNode } from 'react'
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import type { FormOnChange, FormOnChangeParams } from '../../interfaces'

export const radioFormats = ['default', 'outlined', 'toggled'] as const
export type RadioFormatType = (typeof radioFormats)[number]
export type ValueType = string | number

export interface RadioGroupProviderProps {
  allowDeselection?: boolean
  children?: ReactNode
  disabled?: boolean
  format?: RadioFormatType
  hasError?: boolean
  hasWarning?: boolean
  name?: string
  onChange?: FormOnChange<ValueType>
  value?: ValueType
}

type RadioGroupContextType =
  | {
      allowDeselection?: boolean
      disabled?: boolean
      format?: RadioFormatType
      hasError?: boolean
      hasWarning?: boolean
      isChecked: (value?: ValueType) => boolean
      name?: string
      onChange: FormOnChange<ValueType>
    }
  | undefined

const RadioGroupContext = createContext<RadioGroupContextType>(undefined)

const RadioGroupProvider = ({
  allowDeselection = false,
  children,
  disabled = false,
  format = 'default',
  hasWarning,
  hasError,
  name,
  onChange,
  value,
}: RadioGroupProviderProps) => {
  const [selectedValue, setSelectedValue] = useState(value)

  useEffect(() => {
    setSelectedValue(value)
  }, [value])

  const handleChange = useCallback(
    (data: FormOnChangeParams<ValueType>) => {
      if (selectedValue === data.value && allowDeselection) {
        setSelectedValue('')
        onChange?.({ ...data, value: '' })
      } else if (selectedValue !== data.value) {
        setSelectedValue(data.value)
        onChange?.(data)
      }
    },
    [allowDeselection, onChange, selectedValue]
  )

  const isChecked = useCallback((value?: ValueType) => selectedValue === value, [selectedValue])

  const contextValue = useMemo(
    () => ({
      allowDeselection,
      disabled,
      format,
      hasError,
      hasWarning,
      isChecked,
      name,
      onChange: handleChange,
    }),
    [allowDeselection, disabled, format, hasError, hasWarning, isChecked, name, handleChange]
  )

  return <RadioGroupContext.Provider value={contextValue}>{children}</RadioGroupContext.Provider>
}

const useRadioGroupContext = () => {
  const context = useContext(RadioGroupContext)
  if (context === undefined) {
    throw new Error('useRadioGroupContext must be used within a RadioGroupProvider')
  }
  return context
}

export { RadioGroupProvider, useRadioGroupContext }
